import { percentageFormatter } from "../utils/format";
import { Paper, Typography, Divider, Stack, Box, Grid, Chip } from "@mui/material";


export default function ClusterCard (props) {
  // Highlight the -1 (no cluster) label
  const extraChipProps = props.cluster.label === -1 ? {
    color: "error",
    variant: ""
  } : {}

  return (
    <Paper className="dashboard-card" elevation={0} sx={{ pb: 2 }}>
      <Stack sx={{ p: 2 }}>
        <Stack direction="row">
          <Box>
            {props.cluster.labels.map((label) => {
              return (
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ lineHeight: 1.5 }}
                  key={label}
                >
                  {label}
                </Typography>
              )
            })}
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Chip
            label={props.cluster.label}
            variant="outlined"
            {...extraChipProps}
          />
        </Stack>

        <Typography variant="body2" component="div" sx={{ mt: 1.5, color: "lightText.main" }}>
          {props.cluster.confidence >= 1 && "🔥 "}
          {percentageFormatter({ value: props.cluster.confidence })} Average Confidence
        </Typography>
      </Stack>
      <Divider />

      {props.cluster.items.map((item, index) => {
        return (
          <Grid container sx={{ pl: 2, pr: 2, pt: 2 }} key={index}>
            <Grid item xs={8}>
              <Typography variant="body2" component="div">
                {item.text}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Typography variant="body2" component="div">
                {percentageFormatter({ value: item.confidence })}
              </Typography>
            </Grid>
          </Grid>
        )
      })}
    </Paper>
  );
}