import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useApiClient } from "../../api/client";
import { useAuth } from "../../api/auth.context";
import { useNavigate, Outlet } from "react-router-dom";
import { Container, Box } from "@mui/material";

import CardLabel from "../../components/CardLabel";
import CreateEntityFab from "../../components/CreateEntityFab";

import clusteringJobsColumns from "../../data/tables/clustering";


export default function Clustering () {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { currentUser } = useAuth();

  const [clusteringJobs, setClusteringJobs] = useState([]);

  useEffect(() => {
    apiClient.clusteringJobs(currentUser).then((response) => {
      setClusteringJobs(response.data.jobs)
    })
  }, [])

  return (
    <Container sx={{ mt: 4 }} maxWidth={false}>
      <CreateEntityFab
        title={"New Clustering Job"}
        onClick={() => navigate("/clustering/create")}
      />

      <CardLabel text={"Clustering Jobs"} sx={{ mb: 1 }} />
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          columns={clusteringJobsColumns}
          rows={clusteringJobs}
          pageSize={50}
          rowsPerPageOptions={[50]}
          onSelectionModelChange={(ids) => {
            if (ids.length === 0) return;

            navigate(`/clustering/${ids[0]}`);
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Outlet />
      </Box>
    </Container>
  )
}