import { OperationStatus } from "../../data/enums/operations";
import { Typography, Box, LinearProgress, Alert } from "@mui/material";


export default function OperationExecutionStatus (props) {
  const successMessage = props.successMessage || "The operation was executed";
  const inExecutionMessage = props.inExecutionMessage || "The operation is being executed";
  const failedMessage = props.failedMessage || "The operation failed: ";

  return (
    <Box sx={props.sx}>
      {props.status === OperationStatus.IN_EXECUTION && (
        <Box>
          <Typography
            variant="body1"
            component="div"
            sx={{
              marginTop: 2,
              marginBottom: 1
            }}
          >
            {inExecutionMessage}
          </Typography>
          <LinearProgress color="primary" />
        </Box>
      )}

      {props.status === OperationStatus.EXECUTED && (
        <Alert severity="success" sx={{ marginTop: 2 }}>{successMessage}</Alert>
      )}

      {props.status === OperationStatus.FAILED && (
        <Alert severity="error" sx={{ marginTop: 2 }}>{failedMessage} {props.error}</Alert>
      )}
    </Box>
  )
}