import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText
} from "@mui/material";


export default function CrescendaSelect(props) {
  return (
    <FormControl
      sx={props.sx}
      fullWidth={props.fullWidth}
      error={props.error}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={props.value}
        label={props.label}
        onChange={(event) => {
          const selectedOption = props.options.filter((option) => {
            return option.value === event.target.value;
          }).pop();

          props.onChange(selectedOption)
        }}
      >
        {props.options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}