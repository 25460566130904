// Consider replacing with class enums
// https://masteringjs.io/tutorials/fundamentals/enum
const MatchType = {
  EXACT: "exact",
  BROAD: "broad",
  PHRASE: "phrase",
  UNKNOWN: "unknown",
  UNSPECIFIED: "unspecified"
}

const EntityTagType = {
  PERSON: "PER",
  LOCATION: "LOC",
  ORGANIZATION: "ORG",
  MISCELLANEOUS: "MISC"
}

const SearchTermsList = {
  IGNORE: "ignore",
  ASK_CUSTOMER: "ask-customer"
}

const OptimizationReason = {
  LT_50_CK_AND_OLDER_THAN_30_DAYS: "less-50-cliks-older-30-d",
  LT_50_CK_AND_OLDER_30_DAYS_CV_COST_LTEQ_1: "less-50-cliks-older-30-d-conv-cost-less-eq-1",
  GEQ_50_CK_NO_CONV: "no-conv",
  GEQ_50_CK_CV_COST_LTEQ_1: "conv-cost-less-eq-1",
  EMPTY: "no-keywords"
}

function optimizationReasonLabel (reason) {
  switch (reason) {
    case OptimizationReason.LT_50_CK_AND_OLDER_THAN_30_DAYS:
      return "Older than 30 days with less than 50 clicks";
    case OptimizationReason.LT_50_CK_AND_OLDER_30_DAYS_CV_COST_LTEQ_1:
      return "Older than 30 days with less than 50 clicks and (conversion value / cost) ≤ 1";
    case OptimizationReason.GEQ_50_CK_NO_CONV:
      return "More than 50 clicks but no conversions";
    case OptimizationReason.GEQ_50_CK_CV_COST_LTEQ_1:
      return "More than 50 clicks but (conversion value / cost) ≤ 1";
    case OptimizationReason.EMPTY:
      return "No keywords";
  }
}

function matchTypeLabel (matchType) {
  switch (matchType) {
    case MatchType.EXACT:
      return "Exact Match";
    case MatchType.BROAD:
      return "Broad Match";
    case MatchType.PHRASE:
      return "Phrase Match";
    case MatchType.UNKNOWN:
      return "Unknown";
    case MatchType.UNSPECIFIED:
      return "Unspecified";
  }
}

const enumOptionLabels = {
  matchType: [
    "Exact Match",
    "Broad Match",
    "Phrase Match",
    "Unknown",
    "Unspecified"
  ],
  optimizationReason: [
    "Older than 30 days with less than 50 clicks",
    "Older than 30 days with less than 50 clicks and (conversion value / cost) ≤ 1",
    "More than 50 clicks but no conversions",
    "More than 50 clicks but (conversion value / cost) ≤ 1",
    "No keywords"
  ],
  entityStatus: [
    "enabled",
    "paused",
    "removed",
    "unspecified",
    "unknown"
  ],
  placementType: [
    "unspecified",
    "unknown",
    "website",
    "mobile app category",
    "mobile app",
    "youtube video",
    "youtube channel"
  ],
  budgetGroupStatus: [
    "unknown",
    "expired",
    "active"
  ],
  searchTermType: [
    "Positive",
    "Ignore",
    "Negative",
    "Ask Customer"
  ]
}

export {
  MatchType,
  EntityTagType,
  SearchTermsList,
  OptimizationReason,
  optimizationReasonLabel,
  matchTypeLabel,
  enumOptionLabels
};
