import { renderPlacementUrlCell } from "./cells";
import { currencyFormatter, percentageFormatter } from "../../utils/format";
import { enumOptionLabels } from "../../data/enums/generic"

// Placements manager
const placementColumns = [
  { field: "id", headerName: "URL", type: "string", width: 475, renderCell: renderPlacementUrlCell },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    type: "singleSelect",
    valueOptions: enumOptionLabels.placementType
  },
  { field: "clicks", headerName: "Clicks", type: "number" },
  { field: "conversions", headerName: "Conversions", type: "number" },
  { field: "ctr", headerName: "CTR", valueFormatter: percentageFormatter, type: "number" },
  { field: "costConversion", headerName: "Cost per Conversion", width: 200, valueFormatter: currencyFormatter, type: "number" },
  { field: "impressions", headerName: "Impressions", type: "number" },
  { field: "cost", headerName: "Cost", valueFormatter: currencyFormatter, type: "number" },
  { field: "cpc", headerName: "CPC", valueFormatter: currencyFormatter, type: "number" },
  { field: "cpm", headerName: "CPM", valueFormatter: currencyFormatter, type: "number" }
]

export default placementColumns;