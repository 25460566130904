import {
  Box,
  IconButton,
  Stack,
  Divider,
  Typography
} from "@mui/material";

import { logicalOperators } from "../../data/rules";
import { formatSelectOption } from "../../utils/format";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import CrescendaSelect from "../CrescendaSelect"


export default function FilterRow (props) {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      sx={{
        minHeight: 68,
        marginLeft: 2 * props.filter.indentationLevel
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          marginTop: "auto",
          marginBottom: "auto"
        }}
      >
        {props.filter.indentationLevel > 0 && (
          <IconButton
            aria-label="decrease indentation"
            size="small"
            onClick={() => {
              props.updateFilter({
                ...props.filter,
                indentationLevel: props.filter.indentationLevel - 1
              })
            }}
          >
            <ArrowLeftIcon />
          </IconButton>
        )}

        <IconButton
          aria-label="increase indentation"
          size="small"
          onClick={() => {
            props.updateFilter({
              ...props.filter,
              indentationLevel: props.filter.indentationLevel + 1
            })
          }}
        >
          <ArrowRightIcon />
        </IconButton>
      </Stack>

      <Box sx={{ display: "flex" }}>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            marginTop: "auto",
            marginBottom: "auto"
          }}
        >
          {props.filter.field} {props.filter.operator} {props.filter.value.toString()}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1, display: "flex" }} />

      {!props.isTerminal && (
        <Box sx={{ display: "flex" }}>
          <CrescendaSelect
            label="Logical Operator"
            value={props.filter.logicalOperator}
            options={logicalOperators.map((operator) => {
              return formatSelectOption({ value: operator })
            })}
            onChange={(operator) => {
              props.updateFilter({
                ...props.filter,
                logicalOperator: operator.value
              })
            }}
            sx={{
              minWidth: 200,
              marginTop: "auto",
              marginBottom: "auto"
            }}
          />
        </Box>
      )}

      <Stack
        sx={{
          marginTop: "auto !important",
          marginBottom: "auto !important"
        }}
      >
        {props.filter.id > 0 && (
          <IconButton
            aria-label="move up"
            size="small"
            onClick={() => {
              props.moveFilter(props.filter, props.filter.id - 1)
            }}
          >
            <ArrowUpIcon />
          </IconButton>
        )}

        {!props.isTerminal && (
          <IconButton
            aria-label="move down"
            size="small"
            onClick={() => {
              props.moveFilter(props.filter, props.filter.id + 1)
            }}
          >
            <ArrowDownIcon />
          </IconButton>
        )}
      </Stack>

      <Divider
        flexItem
        orientation="vertical"
      />

      <Box sx={{ display: "flex" }}>
        <IconButton
          aria-label="remove filter"
          onClick={() => {
            props.deleteFilter(props.filter)
          }}
          sx={{
            marginTop: "auto",
            marginBottom: "auto"
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Stack>
  )
}