import { isSuperset } from "./set";


const isSubsequence = (sequence, subsequence) => {
  var subsequenceIndex = 0;

  for (var i = 0; i < sequence.length; i++) {
    const item = sequence[i];

    if (item === subsequence[subsequenceIndex]) {
      subsequenceIndex += 1;
    } else {
      subsequenceIndex = 0
    }

    if (subsequenceIndex === subsequence.length) {
      return true;
    }
  }

  return false;
}

const matchesExclusions = (termName, exclusions) => {
  return exclusions.filter((exclusion) => {
    if (exclusion.charAt(0) === '"' && exclusion.charAt(exclusion.length - 1) === '"') {
      // Phrase match exclusion
      const parsedExclusion = exclusion.slice(1, exclusion.length - 1);

      return isSubsequence(termName.split(" "), parsedExclusion.split(" "));
    }

    if (`[${termName}]` === exclusion) {
      // Exact match exclusion
      return true
    }

    // Broad match exclusion: the exclusion tokens must be
    // a subset of the tokens in termName
    const termTokens = new Set(termName.split(" "));
    const exclusionTokens = new Set(exclusion.split(" "));

    return isSuperset(termTokens, exclusionTokens);
  }).length > 0;
}

export {
  isSubsequence,
  matchesExclusions
}