import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiClient } from "../../api/client";
import { useAuth } from "../../api/auth.context";
import { OperationStatus } from "../../data/enums/operations";
import { getFormErrorSkeleton, getFormError } from "../../data/form";
import { Typography, Box, Alert, IconButton, Stack, Tooltip, LinearProgress } from "@mui/material";

import Masonry from "@mui/lab/Masonry";
import ClusterCard from "../../components/ClusterCard"
import clusteringTransformers from "../../utils/transformers/clustering";

import DownloadIcon from "@mui/icons-material/Download";


export default function ClusteringJobDetails () {
  const { id } = useParams();
  const { currentUser } = useAuth();

  const apiClient = useApiClient();

  const [clusteringJob, setClusteringJob] = useState(null);
  const [clusteringJobProgress, setClusteringJobProgress] = useState(0);

  const [error, setError] = useState(getFormErrorSkeleton());

  const handleClusteringJobGetResponse = (response) => {
    setClusteringJob(clusteringTransformers.details(response.data));

    if (response.data.status === OperationStatus.FAILED) {
      setError(getFormError(`Clustering job failed: ${response.data.error}`))
    } else {
      setError(getFormErrorSkeleton())
    }
  }

  const handleClusteringJobGetResponseError = (error) => {
    setError(getFormError(
      error.response ? error.response.data.message : error.toString()
    ))
  }

  useEffect(() => {
    setClusteringJobProgress(0);

    apiClient.clusteringJob(currentUser, id)
      .then(handleClusteringJobGetResponse)
      .catch(handleClusteringJobGetResponseError)
  }, [id])

  useEffect(() => {
    // If the currently selected job is pending refresh its status
    // and progress every five seconds. This function:
    //
    // 1. Schedules a new interval when the job id changes (the user navigates to another job)
    // 2. Calls /jobId/progress every five seconds, which returns the job status and progress
    // 3. If the status != pending refreshes the job data and terminates the interval
    // 4. Otherwise updates the clusteringJobProgress field
    const interval = setInterval(() => {
      apiClient.clusteringJobProgress(currentUser, id).then((response) => {
        if (response.data.status !== OperationStatus.PENDING) {
          clearInterval(interval);

          // Load the clusters once the clustering job has been executed
          apiClient.clusteringJob(currentUser, id)
            .then(handleClusteringJobGetResponse)
            .catch(handleClusteringJobGetResponseError)
        } else {
          setClusteringJobProgress(response.data.progress);
        }
      }).catch((error) => {
        console.log(error);
      })
    }, 5000);

    return () => clearInterval(interval);
  }, [id]);

  const getReport = () => {
    apiClient.clusteringJobReport(currentUser, id).then((fileURL) => {
      window.open(fileURL, "_blank");
      setError(getFormErrorSkeleton());
    }).catch(async (error) => {
      const prefix = "could not generate the report:"

      if (error.response && error.response.data) {
        const jsonResponse = JSON.parse(await error.response.data.text());

        setError(getFormError(`${prefix} ${jsonResponse.message}`, "error"))
      } else {
        setError(getFormError(`${prefix} ${error}`, "error"))
      }
    })
  }

  return (
    <Box sx={{ mt: 4 }}>
      {clusteringJob && (
        <Box>
          <Stack direction="row">
            <Typography
              variant="h5"
              component="div"
              className="vertical-center"
            >
              {clusteringJob.name} clusters
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            {clusteringJob.status === OperationStatus.EXECUTED && (
              <Tooltip title="Download Excel Report">
                <IconButton
                  variant="contained"
                  className="vertical-center"
                  onClick={getReport}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>

          {clusteringJob.status === OperationStatus.PENDING && (
            <Box sx={{ mt: 1, mb: 1 }}>
              <Alert severity="warning" sx={{ mb: 2 }}>
                The clustering job is being executed
              </Alert>

              <LinearProgress
                variant="determinate"
                value={clusteringJobProgress * 100}
              />
            </Box>
          )}

          {error.error && (
            <Alert severity={error.severity} sx={{ mt: 1, mb: 1 }}>
              {error.message}
            </Alert>
          )}

          {clusteringJob.clusters.length > 0 && (
            <Masonry columns={4} spacing={2} sx={{ mt: 1 }}>
              {clusteringJob.clusters.map((cluster, index) => {
                return <ClusterCard cluster={cluster} key={index} />
              })}
            </Masonry>
          )}

          {(clusteringJob.clusters.length === 0 && !error.error && clusteringJob.status === OperationStatus.EXECUTED) && (
            <Typography
              variant="body2"
              component="div"
              sx={{ mt: 1 }}
            >
              Job {clusteringJob.id} has no clusters
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}