import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useAuth } from "../api/auth.context";
import { useApiClient } from "../api/client";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Box,
  Paper,
  Button,
  Stack,
  Alert,
  Container,
  TextField,
  Typography
} from "@mui/material";

import CardLabel from "../components/CardLabel";
import CrescendaSelect from "../components/CrescendaSelect";

import { budgetPacingColumns } from "../data/tables/budget"
import { defaultBudgetGroup, advertisingChannelTypeList } from "../data/budget";

const moment = require("moment");


export default function BudgetPacing() {
  const apiClient = useApiClient();
  const { currentUser } = useAuth();

  // FIXME: Maybe wrap in a reportConfiguration object?
  const [startDate, setStartDate] = useState(moment().startOf("year").toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [timeframeEndDate, setTimeframeEndDate] = useState(moment().subtract(1, "days").toDate());
  const [budget, setBudget] = useState(0);

  const [budgetGroups, setBudgetGroups] = useState([defaultBudgetGroup()]);
  const [currentBudgetGroup, setCurrentBudgetGroup] = useState(defaultBudgetGroup());

  const [advertisingChannelTypes, setAdvertisingChannelTypes] = useState(advertisingChannelTypeList[0]);

  const [error, setError] = useState({
    error: false,
    message: ""
  })

  useEffect(() => {
    apiClient.budgetGroupsPacingMetrics(currentUser).then((groups) => {
      setBudgetGroups([
        defaultBudgetGroup(),
        ...groups
      ]);
    });
  }, [])

  const getReport = function () {
    // Add the budget group unless the default
    // "All campaigns" group was chosen
    const budgetGroupId = (
      currentBudgetGroup.id === defaultBudgetGroup().id
    ) ? null : currentBudgetGroup.id;

    apiClient.budgetPacingReport(
      currentUser,
      startDate,
      endDate,
      timeframeEndDate,
      budget,
      advertisingChannelTypes.value,
      budgetGroupId
    ).then((fileURL) => {
      window.open(fileURL, "_blank");

      setError({
        error: false,
        message: ""
      })
    }).catch(async (error) => {
      console.log(`could not generate the report: ${error}`);

      if (error.response && error.response.data) {
        const jsonResponse = JSON.parse(await error.response.data.text());

        setError({
          error: true,
          message: jsonResponse.message
        })
      } else {
        setError({
          error: true,
          message: error
        })
      }
    })
  }

  return (
    <Container
      sx={{ mt: 4 }}
      maxWidth={false}
    >
      <CardLabel text={"Budget Group"} sx={{ mb: 1 }} />
      <Box sx={{ height: 400, width: "100%", mb: 1 }}>
        <DataGrid
          columns={budgetPacingColumns}
          rows={budgetGroups.slice(1)}
          pageSize={10}
          rowHeight={75}
          rowsPerPageOptions={[10]}
          selectionModel={[currentBudgetGroup.id]}
          onSelectionModelChange={(model) => {
            // Pick the selected budget group or default
            // to the all campaigns one
            var budgetGroupId = defaultBudgetGroup().id

            if (model.length > 0) {
              budgetGroupId = model.pop();
            }

            // Avoid performing unnecessary updates
            if (budgetGroupId === currentBudgetGroup.id) return;

            const budgetGroup = budgetGroups.filter((group) => {
              return group.id === budgetGroupId
            }).pop();

            setCurrentBudgetGroup(budgetGroup);

            if (budgetGroup.id !== defaultBudgetGroup().id) {
              setBudget(budgetGroup.amount);
              setStartDate(budgetGroup.startDate);
              setEndDate(budgetGroup.endDate);
            }
          }}
        />
      </Box>

      <Typography variant="body2" component="div" sx={{ mb: 2 }}>
        Currently using the {currentBudgetGroup.name} budget group
      </Typography>

      <CardLabel text={"Report Configuration"} sx={{ marginBottom: 1 }} />

      {error.error && (
        <Alert
          severity="error"
          sx={{
            marginTop: 1,
            marginBottom: 1
          }}
        >
          {error.message}
        </Alert>
      )}

      <Paper elevation={0} className="dashboard-card" sx={{ paddingTop: 2 }}>
        <Stack sx={{ p: 2, pt: 0 }}>
          <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
            <DesktopDatePicker
              label="Start Date"
              value={startDate}
              onChange={(newDate) => {
                setStartDate(newDate.toDate());
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />

            <DesktopDatePicker
              label="End Date"
              value={endDate}
              onChange={(newDate) => {
                setEndDate(newDate.toDate());
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Stack>

          <DesktopDatePicker
            label="Timeframe End"
            value={timeframeEndDate}
            onChange={(newDate) => {
              setTimeframeEndDate(newDate.toDate());
            }}
            renderInput={(params) => <TextField sx={{ marginBottom: 2}} fullWidth {...params} />}
          />

          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Total Budget (Account's Currency)"
              type="number"
              value={budget}
              onChange={(event) => {
                setBudget(event.target.value);
              }}
            />

            <CrescendaSelect
              fullWidth
              label="Advertising Channel Type"
              options={advertisingChannelTypeList}
              value={advertisingChannelTypes.value}
              onChange={(value) => {
                setAdvertisingChannelTypes(value)
              }}
            />
          </Stack>
        </Stack>
      </Paper>

      <Box
        sx={{
          mt: 2,
          mb: 2,
          textAlign: "right"
        }}
      >
        <Button variant="contained" onClick={getReport}>Execute</Button>
      </Box>
    </Container>
  )
}
