import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "../App";
import Login from "../views/Login";
import Explore from "../views/Explore";
import Settings from "../views/Settings";
import Dashboard from "../views/Dashboard";
import Placements from "../views/Placements";
import BudgetPacing from "../views/BudgetPacing";
import Documentation from "../views/Documentation";
import CampaignBudgetEditor from "../views/CampaignBudgetEditor";

import KeywordsExtraction from "../views/KeywordsExtraction";
import ClusteringJobs from "../views/clustering/ClusteringJobs";
import CreateClusteringJob from "../views/clustering/CreateClusteringJob";
import ClusteringJobDetails from "../views/clustering/ClusteringJobDetails";

import KeywordsPerformaceOperationPreview from "../views/operations/KeywordsPerformaceOperationPreview";
import AdGroupsOptimizationOperationPreview from "../views/operations/AdGroupsOptimizationOperationPreview";
import KeyworsdBidOptimizationOperationPreview from "../views/operations/KeyworsdBidOptimizationOperationPreview";

import SearchTermsReviewOperationSummary from "../views/operations/SearchTermsReviewOperationSummary";
import SearchTermsReviewOperationPreview from "../views/operations/SearchTermsReviewOperationPreview";

import { operationPreviewPathElement, OperationType } from "../data/enums/operations";


const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<App />}>
          <Route index element={<Dashboard />} />
          <Route path="help" element={<Documentation />} />
          <Route path="budget-pacing" element={<BudgetPacing />} />
          <Route path="placements" element={<Placements />} />
          <Route path="explore" element={<Explore />} />

          <Route path="/clustering" element={<ClusteringJobs />}>
            <Route path="/clustering/:id" element={<ClusteringJobDetails />} />
          </Route>

          <Route path="/clustering/create" element={<CreateClusteringJob />} />

          <Route path="keywords-extraction" element={<KeywordsExtraction />} />
          <Route path="campaign-budget-editor" element={<CampaignBudgetEditor />} />
          <Route path="settings" element={<Settings />} />

          {/* Operation preview routes */}
          <Route
            path={`operations/preview/${operationPreviewPathElement(OperationType.SEARCH_TERMS)}/:operationId`}
            element={<SearchTermsReviewOperationPreview />}
          />

          <Route
            path={`operations/preview/${operationPreviewPathElement(OperationType.SEARCH_TERMS)}/:operationId/summary`}
            element={<SearchTermsReviewOperationSummary />}
          />

          <Route
            path={`operations/preview/${operationPreviewPathElement(OperationType.AD_GROUPS_OPTIMIZATION)}/:operationId`}
            element={<AdGroupsOptimizationOperationPreview />}
          />

          <Route
            path={`operations/preview/${operationPreviewPathElement(OperationType.KEYWORDS_PERFORMANCE)}/:operationId`}
            element={<KeywordsPerformaceOperationPreview />}
          />

          <Route
            path={`operations/preview/${operationPreviewPathElement(OperationType.CPC_UPDATE)}/:operationId`}
            element={<KeyworsdBidOptimizationOperationPreview />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes };
