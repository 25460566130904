import { Tooltip, Fab } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

export default function CreateEntityFab(props) {
  return (
    <Tooltip title={props.title} placement="left">
      <Fab
        color="primary"
        onClick={props.onClick}
        sx={{
          top: 28,
          right: 32,
          zIndex: 1051,
          position: "absolute",
        }}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  )
}