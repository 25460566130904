const getFormErrorSkeleton = () => {
  return {
    error: false,
    message: "",
    severity: ""
  }
}

const getFormError = (message, severity) => {
  return {
    error: true,
    message: message,
    severity: severity ?? "error"
  }
}

export {
  getFormErrorSkeleton,
  getFormError
};