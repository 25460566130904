import { AdvertisingChannelType } from "./enums/ads";


const budgetAlgorithmList = [{
  label: "Linear",
  value: "linear"
}];

const budgetAlgorithmMode = {
  add: {
    label: "Add",
    value: "add"
  },
  remove: {
    label: "Remove",
    value: "remove"
  }
}

const budgetAlgorithmOrder = {
  ascending: {
    label: "Ascending",
    value: "ascending"
  },
  descending: {
    label: "Descending",
    value: "descending"
  }
}

const budgetAlgorithmMetricList = [
  {
    label: "Impressions",
    value: "impressions"
  },
  {
    label: "Conversions",
    value: "conversions"
  },
  {
    label: "Conversion Value",
    value: "conversionValue"
  },
  {
    label: "CTR",
    value: "ctr"
  },
  {
    label: "Average CPC",
    value: "averageCpc"
  },
  {
    label: "Lost Impressions",
    value: "lostImpressions"
  },
  {
    label: "Lost Clicks",
    value: "lostClicks"
  },
  {
    label: "Available Daily Extra Capacity",
    value: "availableDailyCapacity"
  },
  {
    label: "Conversion Rate",
    value: "conversionRate"
  },
  {
    label: "Cost per Conversion",
    value: "costPerConversion"
  }
];

const advertisingChannelTypeList = [
  {
    label: "All",
    value: [
      AdvertisingChannelType.SEARCH,
      AdvertisingChannelType.DISPLAY,
      AdvertisingChannelType.SMART
    ]
  },
  {
    label: "Search",
    value: [AdvertisingChannelType.SEARCH]
  },
  {
    label: "Display",
    value: [AdvertisingChannelType.DISPLAY]
  },
  {
    label: "Smart Campaigns",
    value: [AdvertisingChannelType.SMART]
  }
];

// Minimum value that indicates a budget change
const minimumBudgetDelta = 0.01;

// Default budget group that includes all the account campaigns
const defaultBudgetGroup = () => {
  return {
    id: "all-campaigns",
    campaignIds: [],
    name: "Default (All Campaigns)"
  }
}

export {
  budgetAlgorithmList,
  budgetAlgorithmMode,
  budgetAlgorithmOrder,
  budgetAlgorithmMetricList,
  minimumBudgetDelta,
  advertisingChannelTypeList,
  defaultBudgetGroup
}