import { Box, Typography } from "@mui/material";


export default function Field (props) {
  const sx = props.fullWidth ? { width: "100%", ...props.sx ?? {} } : props.sx ?? {};

  return (
    <Box sx={sx}>
      <Typography variant="subtitle1" component="div" sx={{ fontWeight: 500 }}>
          {props.name}
      </Typography>
      <Typography variant="body2" component="div">
        {props.value}
      </Typography>
    </Box>
  )
}