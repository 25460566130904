import { validateSearchTerm } from "../operations";
import { micros2amount, roundAmount } from "../ads";
import { EntityTagType } from "../../data/enums/generic";
import { optimizationReasonLabel, matchTypeLabel } from "../../data/enums/generic";
import { NegativeSearchTermLevel, SearchTermType } from "../../data/enums/operations";

const searchTermsEntities = (item) => {
  const organizations = item.searchTerm.entities.filter(e => {
    return e.type === EntityTagType.ORGANIZATION;
  });

  return {
    organizations: organizations.map(e => e.text).join("; "),
    hasOrganizations: organizations.length > 0
  }
}

const searchTermsPositive = (item) => {
  return {
    id: item.searchTerm.id,
    warnings: validateSearchTerm(item.searchTerm.name),
    searchTerm: item.searchTerm.name,
    daysSinceCreation: item.searchTerm.daysSinceCreation,
    keyword: item.keyword.name,
    matchType: matchTypeLabel(item.keyword.matchType),
    adGroup: item.adGroup.name,
    adGroupId: item.adGroup.id,
    campaignName: item.campaign.name,
    campaignId: item.campaign.id,
    weekImpressions: item.metrics.weekImpressions,
    allTimeImpressions: item.metrics.allTimeImpressions,
    ...searchTermsEntities(item),
  }
}

const searchTermsNegative = (item) => {
  return {
    ...searchTermsPositive(item),
    level: NegativeSearchTermLevel.CAMPAIGN,
    type: SearchTermType.NEGATIVE
  }
}

const searchTermsPositiveSKAG = (item) => {
  return {
    id: item.searchTerm.id,
    warnings: validateSearchTerm(item.searchTerm.name),
    searchTerm: item.searchTerm.name,
    daysSinceCreation: item.searchTerm.daysSinceCreation,
    adGroup: item.adGroup.name,
    adGroupId: item.adGroup.id,
    campaignName: item.campaign.name,
    campaignId: item.campaign.id,
    weekImpressions: item.metrics.weekImpressions,
    allTimeImpressions: item.metrics.allTimeImpressions,
    ...searchTermsEntities(item),
  }
}

const searchTermsNegativeSKAG = (item) => {
  return {
    ...searchTermsPositiveSKAG(item),
    level: NegativeSearchTermLevel.CAMPAIGN,
    type: SearchTermType.NEGATIVE
  }
}

const keywordsPerformance = (item) => {
  return {
    id: `${item.adGroup.id}/${item.keyword.id}`,
    keyword: item.keyword.name,
    matchType: item.keyword.matchType,
    adGroup: item.adGroup.name,
    campaignName: item.campaign.name,
    daysSinceCreation: item.keyword.daysSinceCreation,
    optimizationReason: optimizationReasonLabel(item.optimizationReason),
    clicks: item.metrics.clicks,
    conversions: item.metrics.conversions,
    conversionValue: item.metrics.conversionValue,
    cost: micros2amount(item.metrics.cost),
    conversionValueCost: item.metrics.conversionValueCost,
    costConversion: roundAmount(item.metrics.costConversions)
  }
}

const adGroupsPerformance = (item) => {
  return {
    id: item.adGroup.id,
    adGroup: item.adGroup.name,
    campaignName: item.campaign.name,
    daysSinceCreation: item.adGroup.daysSinceCreation,
    optimizationReason: optimizationReasonLabel(item.optimizationReason),
    clicks: item.metrics.clicks,
    conversions: item.metrics.conversions,
    conversionValue: item.metrics.conversionValue,
    cost: micros2amount(item.metrics.cost),
    conversionValueCost: item.metrics.conversionValueCost,
    costConversion: roundAmount(item.metrics.costConversions)
  }
}

const keywordsBidOptimization = (item) => {
  return {
    id: `${item.adGroup.id}/${item.keyword.id}`,
    keyword: item.keyword.name,
    matchType: matchTypeLabel(item.keyword.matchType),
    adGroup: item.adGroup.name,
    campaignName: item.campaign.name,
    maxCpc: micros2amount(item.keyword.bid.maxCpc),
    proposedMaxCpc: micros2amount(item.keyword.bid.proposedMaxCpc),
    conversions: item.metrics.conversions,
    conversionValueCost: item.metrics.conversionValueCost,
    topImpressionPercentage: item.metrics.searchImpressionShare.top,
    absoluteTopImpressionPercentage: item.metrics.searchImpressionShare.absoluteTop
  }
}

const operationTransformers = {
  searchTerms: {
    regular: {
      positive: searchTermsPositive,
      negative: searchTermsNegative
    },
    skag: {
      positive: searchTermsPositiveSKAG,
      negative: searchTermsNegativeSKAG
    }
  },
  keywordsPerformance: keywordsPerformance,
  adGroupsPerformance: adGroupsPerformance,
  keywordsBidOptimization: keywordsBidOptimization
}

export default operationTransformers;
