import { getFormErrorSkeleton } from "./form";

const FieldDataType = {
  ENUM: "enum",
  STRING: "string",
  DOUBLE: "double",
  INTEGER: "integer",
  BOOLEAN: "boolean",
  MICROS: "micros",
}

const dataType2operator = {
  enum: ["=", "!="],
  integer: [
    "=",
    "!=",
    "<",
    "<=",
    ">",
    ">="
  ],
  double: [
    "=",
    "!=",
    "<",
    "<=",
    ">",
    ">="
  ],
  string: [],
  boolean: [
    "="
  ],
}

const googleAdsTimeframes = [
  // Custom date range, equivalent to specifying
  // no date range on Google Ads
  {
    name: "All time",
    value: "ALL_TIME",
    description: "all time"
  },
  {
    name: "Today",
    value: "TODAY",
    description: "today only"
  },
  {
    name: "Yesterday",
    value: "YESTERDAY",
    description: "yesterday only"
  },
  {
    name: "Last 7 days",
    value: "LAST_7_DAYS",
    description: "the last 7 days not including today"
  },
  {
    name: "Last business week",
    value: "LAST_BUSINESS_WEEK",
    description: "the 5 day business week, Monday through Friday, of the previous business week"
  },
  {
    name: "This month",
    value: "THIS_MONTH",
    description: "all days in the current month"
  },
  {
    name: "Last month",
    value: "LAST_MONTH",
    description: "all days in the previous month"
  },
  {
    name: "Last 14 days",
    value: "LAST_14_DAYS",
    description: "the last 14 days not including today"
  },
  {
    name: "Last 30 days",
    value: "LAST_30_DAYS",
    description: "the last 30 days not including today"
  },
  {
    name: "This week Sunday -> Today",
    value: "THIS_WEEK_SUN_TODAY",
    description: "the period between the previous Sunday and the current day"
  },
  {
    name: "This week Monday -> Today",
    value: "THIS_WEEK_MON_TODAY",
    description: "the period between the previous Monday and the current day"
  },
  {
    name: "Last week Sunday -> Saturday",
    value: "LAST_WEEK_SUN_SAT",
    description: "the 7-day period starting with the previous Sunday"
  },
  {
    name: "Last week Monday -> Sunday",
    value: "LAST_WEEK_MON_SUN",
    description: "the 7-day period starting with the previous Monday"
  }
]

const timeframeByValue = (value) => {
  return structuredClone(
    googleAdsTimeframes.filter((timeframe) => timeframe.value === value)[0]
  );
}

const baseFormErrors = {
  field: getFormErrorSkeleton(),
  operator: getFormErrorSkeleton(),
  value: getFormErrorSkeleton(),
  enumValue: getFormErrorSkeleton()
}

const logicalOperators = ["AND", "OR"];
const booleanValues = {
  true: "true",
  false: "false",
  all: ["true", "false"]
};

const rulesSkeletons = {
  report: {
    name: "",
    fields: {
      id: {
        name: ""
      },
      resource: [],
      metrics: [],
      crescenda: []
    },
    actions: []
  },
  reportData: {
    name: "",
    columns: [],
    rows: []
  },
  executionLog: {
    failed: [],
    executed: []
  }
}

const filterSkeleton = {
  field: "",
  operator: "",
  value: "",
  enumValue: "",
}

export {
  FieldDataType,
  dataType2operator,
  baseFormErrors,
  logicalOperators,
  googleAdsTimeframes,
  rulesSkeletons,
  booleanValues,
  filterSkeleton,
  timeframeByValue
};