import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useApiClient } from "../api/client";
import { useAuth } from "../api/auth.context";
import { currentDateTimeString } from "../utils/time";
import { OperationStatus } from "../data/enums/operations";
import { Tabs, Tab, Box, Container, Paper, TextField, Button } from "@mui/material";
import { keywordsExtractionColumns, keywordsExtractionVisibilityModel } from "../data/tables/keywordsExtraction";

import Field from "../components/Field";
import CardLabel from "../components/CardLabel";
import CrescendaDropzone from "../components/CrescendaDropzone";
import DataGridExportToolbar from "../components/DataGridExportToolbar";
import OperationExecutionStatus from "../views/operations/OperationExecutionStatus";


export default function KeywordsExtraction () {
  const { currentUser } = useAuth();
  const apiClient = useApiClient();

  const KeywordsExtractionTab = { TEXT: 0, FILE: 1 }

  const [currentTab, setCurrentTab] = useState(KeywordsExtractionTab.TEXT);
  const [keywords, setKeywords] = useState([]);
  
  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState({
    data: null,
    mime: null,
    filename: null
  });

  const [operationStatus, setOperationStatus] = useState({
    status: OperationStatus.PENDING,
    error: ""
  });

  const extractKeywords = () => {
    setOperationStatus({
      error: "",
      status: OperationStatus.IN_EXECUTION
    });

    const successHandler = (response) => {
      // Add an id to the returned keywords, as required by the DataGrid
      const parsedKeywords = response.data.keywords.map((keyword, i) => {
        return { ...keyword, id: i }
      });

      setKeywords(parsedKeywords);
      setOperationStatus({
        error: "",
        status: OperationStatus.EXECUTED
      });
    }

    const errorHandler = (error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setOperationStatus({
        error: message,
        status: OperationStatus.FAILED
      });
    };

    if (currentTab === KeywordsExtractionTab.TEXT) {
      apiClient.extractFromText(
        currentUser,
        textInput
      ).then(successHandler).catch(errorHandler)
    } else if (currentTab === KeywordsExtractionTab.FILE) {
      apiClient.extractFromFile(
        currentUser,
        fileInput.data,
        fileInput.mime
      ).then(successHandler).catch(errorHandler)
    } else {
      setOperationStatus({
        error: "invalid input type",
        status: OperationStatus.FAILED
      });
    }
  }

  return (
    <Container sx={{ mt: 4 }} maxWidth={false}>
      <Paper elevation={0} className="dashboard-card">
        <Box className="tabs-container">
          <Tabs
            value={currentTab}
            onChange={(_, value) => setCurrentTab(value)}
          >
            <Tab label="From Text" />
            <Tab label="From Document" />
          </Tabs>
        </Box>

        <Box sx={{ m: 2 }}>
          <OperationExecutionStatus
            successMessage={"Keywords extracted"}
            failedMessage={"Failed to extract keywords: "}
            inExecutionMessage={"Extracting keywords..."}
            status={operationStatus.status}
            error={operationStatus.error}
            sx={{ p: 0, mb: 2 }}
          />
        </Box>

        <Box sx={{ m: 2 }} hidden={currentTab !== KeywordsExtractionTab.TEXT}>
          <TextField
            label="Input"
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            value={textInput}
            onChange={(event) => setTextInput(event.target.value)}
            helperText={"Paste your text here"}
          />
        </Box>

        <Box sx={{ m: 2 }} hidden={currentTab !== KeywordsExtractionTab.FILE}>
          {fileInput.filename && (
            <Field
              name="Filename"
              value={fileInput.filename}
            />
          )}

          <CrescendaDropzone
            label="Drag and drop a document here or click to browse. Only PDF and Word (.docx) files are supported"
            accept={{
              "application/pdf": [".pdf"],
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
            }}
            onDrop={(acceptedFiles) => {
              const reader = new FileReader();
  
              reader.onload = function (event) {
                setFileInput({
                  filename: acceptedFiles[0].name,
                  data: event.target.result,
                  mime: acceptedFiles[0].type
                })
              };
  
              reader.readAsArrayBuffer(acceptedFiles[0]);
            }}
          />
        </Box>
      </Paper>

      <Box sx={{ mt: 2, mb: 2, textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={extractKeywords}
        >
            Extract Keywords
        </Button>
      </Box>

      {keywords.length > 0 && (
        <Box sx={{ height: 400, width: "100%" }}>
          <CardLabel text={"Extracted Keywords"} sx={{ mb: 1 }} />
          <DataGrid
            rows={keywords}
            columns={keywordsExtractionColumns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            components={{ Toolbar: DataGridExportToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { fileName: `Extracted Keywords ${currentDateTimeString()}` }
              }
            }}
            initialState={{
              columns: {
                columnVisibilityModel: keywordsExtractionVisibilityModel
              }
            }}
          />
        </Box>
      )}
    </Container>
  )
}