import { NavLink } from "react-router-dom";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";


export default function DrawerNavLink(props) {
  return (
    <ListItem disablePadding>
      <NavLink
        to={props.to}
        className={({ isActive }) => isActive ? "drawer-link-active drawer-link" : "drawer-link"}
      >
        <ListItemButton
          sx={{
            mb: 0.5,
            borderRadius: 3
          }}
        >
          <ListItemIcon>
            {props.icon}
          </ListItemIcon>
          <ListItemText primary={props.title} />
        </ListItemButton>
      </NavLink>
    </ListItem>
  )
}