import { intFormatter, timestampFormatter } from "../../utils/format";


const clusteringJobsColumns = [
  { field: "id", headerName: "ID", type: "string", width: 250 },
  { field: "name", headerName: "Name", type: "string", width: 250 },
  { field: "status", headerName: "Status", type: "string", width: 250 },
  { field: "generated", headerName: "Date", type: "dateTime", width: 300, valueFormatter: timestampFormatter },
  { field: "clustersCount", headerName: "No. of Clusters", type: "number", width: 200, valueFormatter: intFormatter }
]

export default clusteringJobsColumns;