import { Stack, Typography, Box } from "@mui/material";

import WarningIcon from "@mui/icons-material/Warning";


export default function PartialFailureLogRow (props) {
  return (
    <Box>
      <Stack direction={"row"}>
        <WarningIcon
          sx={{
            margin: "auto",
            color: "warning.main",
            marginRight: 1
          }}
        />
        <Typography
          className="log-text"
          variant="body2"
          component="div"
          gutterBottom
          sx={{ flexGrow: 1, marginTop: "auto", marginBottom: "auto" }}
        >
          {props.result.item}: executed with partial failures ({props.result.partialFailures.join("; ")})
        </Typography>
      </Stack>
    </Box>
  )
}