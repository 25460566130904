import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useApiClient } from "../api/client";
import { useAuth } from "../api/auth.context";
import {
  Box,
  Grid,
  Chip,
  Stack,
  Paper,
  Button,
  TextField,
  IconButton,
  Container,
  Typography,
  Autocomplete,
  CircularProgress
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import CardLabel from "../components/CardLabel";
import AlertDialog from "../components/AlertDialog";
import ExecuteButton from "./operations/ExecuteButton";
import TimeframeInput from "../components/TimeframeInput";
import DataGridExportToolbar from "../components/DataGridExportToolbar";
import OperationExecutionStatus from "./operations/OperationExecutionStatus";

import { emptyCampaign } from "../data/settings";
import { SearchTermsList } from "../data/enums/generic";
import { budgetGroupsColumns, searchTermsListColumns } from "../data/tables/settings";
import { OperationStatus, defaultOperationStatus } from "../data/enums/operations";
import {
  CrescendaSnackbar,
  errorSnackbarState,
  defaultSnackbarState,
  successSnackbarState
} from "../components/CrescendaSnackbar";

import { budgetGroupsTransformer } from "../utils/transformers/budgetGroups";

const moment = require("moment");


export default function Settings() {
  // FIXME: Move budget groups elements to its own component
  const apiClient = useApiClient();
  const { currentUser } = useAuth();

  const [newBudgetGroupDialogOpen, setNewBudgetGroupDialogOpen] = useState(false);

  const [budgetGroupIdForDeletion, setBudgetGroupIdForDeletion] = useState("");
  const [deleteBudgetGroupDialogOpen, setDeleteBudgetGroupDialogOpen] = useState(false);

  // Search terms lists
  const [ignoredSearchTermsLists, setIgnoredSearchTermsLists] = useState([])
  const [askCustomerSearchTermsLists, setAskCustomerSearchTermsLists] = useState([])

  // Campaigns list and the currently selected
  // campaign in the Autocomplete
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsLoaded, setCampaignsLoaded] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({ ...emptyCampaign });

  // List of remote budget groups
  const [budgetGroups, setBudgetGroups] = useState([]);

  // Information about the budget group currently being edited
  const [currentBudgetGroupId, setCurrentBudgetGroupId] = useState("");
  const [currentBudgetGroupName, setCurrentBudgetGroupName] = useState("");
  const [currentBudgetGroupAmount, setCurrentBudgetGroupAmount] = useState(0);
  const [currentBudgetGroupCampaigns, setCurrentBudgetGroupCampaigns] = useState([]);

  const [currentBudgetGroupStartDate, setCurrentBudgetGroupStartDate] = useState(moment().startOf("year").toDate());
  const [currentBudgetGroupEndDate, setCurrentBudgetGroupEndDate] = useState(moment().startOf("day").toDate());

  const [snackbarData, setSnackbarData] = useState(defaultSnackbarState());
  const [operationStatus, setOperationStatus] = useState(structuredClone(defaultOperationStatus));

  const isEditingBudget = currentBudgetGroupId !== "";
  const budgetCampaignChips = [...currentBudgetGroupCampaigns].map((campaign) => {
    return (
      <Grid item key={campaign.id}>
        <Chip
          label={campaign.name}
          variant="outlined"
          onDelete={() => {
            setCurrentBudgetGroupCampaigns(currentBudgetGroupCampaigns.filter((c) => {
              return c.id !== campaign.id
            }))
          }}
        />
      </Grid>
    )
  })

  useEffect(() => {
    apiClient.campaigns(currentUser, false).then((campaigns) => {
      setCampaigns([
        structuredClone(emptyCampaign),
        ...campaigns
      ])

      setCampaignsLoaded(true)
    })

    apiClient.budgetGroups(currentUser).then((groups) => {
      setBudgetGroups(groups)
    })

    // Fetch the account-level search terms lists
    apiClient.searchTermsList(currentUser, SearchTermsList.IGNORE).then((searchTerms) => {
      setIgnoredSearchTermsLists(searchTerms.map((term) => ({ id: term })))
    })

    apiClient.searchTermsList(currentUser, SearchTermsList.ASK_CUSTOMER).then((searchTerms) => {
      setAskCustomerSearchTermsLists(searchTerms.map((term) => ({ id: term })))
    })
  }, [])

  /**
   * Handles API errors returned when creating
   * or updating budget groups 
   */
  const handleApiEditError = (error) => {
    if (error.response) {
      setOperationStatus({
        status: OperationStatus.FAILED,
        error: error.response.data.message
      });
    } else {
      setOperationStatus({
        status: OperationStatus.FAILED,
        error: error.toString()
      });
    }
  }

  const createBudgetGroup = (campaignIds) => {
    apiClient.saveBudgetGroup(
      currentUser,
      currentBudgetGroupName,
      currentBudgetGroupAmount,
      campaignIds,
      currentBudgetGroupStartDate,
      currentBudgetGroupEndDate
    ).then((response) => {
      setOperationStatus({
        ...operationStatus,
        status: OperationStatus.EXECUTED
      });

      setCurrentBudgetGroupId(response.data.id);
      setBudgetGroups([
        ...budgetGroups,
        budgetGroupsTransformer(response.data)
      ]);
    }).catch(handleApiEditError);
  }

  const updateBudgetGroup = (campaignIds) => {
    apiClient.updateBudgetGroup(
      currentUser,
      currentBudgetGroupId,
      currentBudgetGroupName,
      currentBudgetGroupAmount,
      campaignIds,
      currentBudgetGroupStartDate,
      currentBudgetGroupEndDate
    ).then((response) => {
      setOperationStatus({
        ...operationStatus,
        status: OperationStatus.EXECUTED
      });

      // Update table record
      setBudgetGroups(budgetGroups.map((group) => {
        if (group.id === response.data.id) {
          return budgetGroupsTransformer(response.data);
        }

        return group;
      }));
    }).catch(handleApiEditError);
  }

  const saveBudgetGroup = () => {
    setOperationStatus({
      ...operationStatus,
      status: OperationStatus.IN_EXECUTION
    });

    const campaignIds = currentBudgetGroupCampaigns.map((campaign) => campaign.id);

    if (isEditingBudget) {
      updateBudgetGroup(campaignIds);
    } else {
      createBudgetGroup(campaignIds);
    }
  }

  const clearForm = () => {
    setCurrentBudgetGroupId("")
    setCurrentBudgetGroupName("")
    setCurrentBudgetGroupCampaigns([])
  }

  const deleteBudgetGroup = () => {
    setDeleteBudgetGroupDialogOpen(false);

    apiClient.deleteBudgetGroup(
      currentUser,
      budgetGroupIdForDeletion
    ).then((response) => {
      setSnackbarData(successSnackbarState("Budget group deleted"));

      if (currentBudgetGroupId === budgetGroupIdForDeletion) {
        clearForm();
      }

      setBudgetGroups(budgetGroups.filter((group) => {
        return group.id !== budgetGroupIdForDeletion;
      }));

      setBudgetGroupIdForDeletion("");
    }).catch((error) => {
      let message = error.toString();

      if (error.response) {
        message = error.response.data.message;
      }

      setBudgetGroupIdForDeletion("");
      setSnackbarData(errorSnackbarState(message));
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <CardLabel text={"Search Terms Lists - Ignore"} sx={{ mb: 1 }} />
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              columns={searchTermsListColumns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              components={{ Toolbar: DataGridExportToolbar }}
              rows={ignoredSearchTermsLists}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <CardLabel text={"Search Terms Lists - Ask Customer"} sx={{ mb: 1 }} />
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              columns={searchTermsListColumns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              components={{ Toolbar: DataGridExportToolbar }}
              rows={askCustomerSearchTermsLists}
            />
          </Box>
        </Grid>
      </Grid>

      <CardLabel text={"Budget Groups"} sx={{ mb: 1 }} />
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={budgetGroups}
          columns={[
            ...budgetGroupsColumns,
            {
              field: "actions",
              type: "actions",
              headerName: "Actions",
              getActions: (params) => {
                return [
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    onClick={() => {
                      setDeleteBudgetGroupDialogOpen(true);
                      setBudgetGroupIdForDeletion(params.id);
                    }}
                    label="Delete"
                  />
                ];
              }
            }
          ]}
          pageSize={10}
          rowsPerPageOptions={[10]}
          onSelectionModelChange={(model) => {
            if (model.length === 0) return;

            const budgetGroup = budgetGroups.filter((group) => {
              return group.id === model[0]
            }).pop();

            const campaignIds = new Set(budgetGroup.campaignIds);
            const budgetGroupCampaigns = campaigns.filter((c) => {
              return campaignIds.has(c.id)
            })

            setCurrentBudgetGroupId(budgetGroup.id);
            setCurrentBudgetGroupName(budgetGroup.name);
            setCurrentBudgetGroupAmount(budgetGroup.amount);
            setCurrentBudgetGroupCampaigns(budgetGroupCampaigns);

            setCurrentBudgetGroupStartDate(structuredClone(budgetGroup.startDate))
            setCurrentBudgetGroupEndDate(structuredClone(budgetGroup.endDate))
          }}
        />
      </Box>

      {/* Edit/Create form header */}
      <Box>
        {!isEditingBudget && (
          <CardLabel text={"New Budget Group"} sx={{ mt: 2, mb: 1 }} />
        )}

        {isEditingBudget && (
          <Stack sx={{ mt: 2, mb: 1 }} direction={"row"}>
            <Box sx={{ width: "100%" }}>
              <CardLabel text={"Edit Budget Group"} />

              <Typography
                variant="body2"
                component="div"
              >
                Editing budget group with ID {currentBudgetGroupId}
              </Typography>
            </Box>

            <IconButton
              className="vertical-center"
              onClick={() => {
                setNewBudgetGroupDialogOpen(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        )}
      </Box>

      <Paper elevation={0} className="dashboard-card" sx={{ p: 2 }}>
        {campaignsLoaded && (
          <Box>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Budget Name"
                variant="outlined"
                value={currentBudgetGroupName}
                onChange={(event) => {
                  setCurrentBudgetGroupName(event.target.value);
                }}
              />

              <TextField
                fullWidth
                label="Budget Amount (Account's Currency)"
                variant="outlined"
                type="number"
                value={currentBudgetGroupAmount}
                onChange={(event) => {
                  setCurrentBudgetGroupAmount(event.target.value);
                }}
              />
            </Stack>

            <TimeframeInput
              sx={{ mt: 2 }}
              showRefresh={false}
              isLoading={false}
              startDate={currentBudgetGroupStartDate}
              endDate={currentBudgetGroupEndDate}
              onStartDateChange={(date) => setCurrentBudgetGroupStartDate(date.toDate())}
              onEndDateChange={(date) => setCurrentBudgetGroupEndDate(date.toDate())}
            />

            {currentBudgetGroupCampaigns.length > 0 && (
              <Grid container spacing={1} sx={{ mt: 1 }}>
                {budgetCampaignChips}
              </Grid>
            )}

            <Stack
              spacing={1}
              sx={{ mt: 2 }}
              direction={"row"}
            >
              <Autocomplete
                disablePortal
                options={campaigns.filter((c) => {
                  return !currentBudgetGroupCampaigns.includes(c)
                })}
                fullWidth
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Campaign"
                    />
                  )
                }}
                value={selectedCampaign}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id
                }}
                onChange={(event, campaign) => setSelectedCampaign(campaign)}
              />

              <IconButton
                className="vertical-center"
                onClick={() => {
                  // Avoid adding the empty campaign to the budget
                  if (selectedCampaign.id === emptyCampaign.id) {
                    return
                  }

                  setCurrentBudgetGroupCampaigns([
                    ...currentBudgetGroupCampaigns,
                    selectedCampaign
                  ]);

                  setSelectedCampaign({ ...emptyCampaign })
                }}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Box>
        )}

        {!campaignsLoaded && (
          <Stack direction="row">
            <CircularProgress />
            <Typography
              sx={{ ml: 2 }}
              variant="subtitle1"
              component="div"
              className="vertical-center"
            >
              Loading campaigns
            </Typography>
          </Stack>
        )}
      </Paper>

      <Box>
        <OperationExecutionStatus
          status={operationStatus.status}
          error={operationStatus.error}
          successMessage={"Budget saved"}
          inExecutionMessage={"Saving budget"}
          failedMessage={"Could not save budget: "}
        />
      </Box>

      <Box sx={{ mt: 2, textAlign: "right" }}>
        <ExecuteButton
          label={isEditingBudget ? "Update Budget" : "Save Budget"}
          allowsMultiple={true}
          execute={saveBudgetGroup}
          operationStatus={operationStatus.status}
        />
      </Box>

      <AlertDialog
        title={"Do you want to create a new budget group?"}
        content={"This action will discard your changes"}
        open={newBudgetGroupDialogOpen}
        onClose={() => {
          setNewBudgetGroupDialogOpen(false)
        }}
      >
        <Button
          onClick={() => {
            setNewBudgetGroupDialogOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          onClick={() => {
            // Clear the form to create a new budget
            clearForm()
            setNewBudgetGroupDialogOpen(false)
          }}
        >
          Confirm
        </Button>
      </AlertDialog>

      <AlertDialog
        title={"Do you want to delete the budget group?"}
        content={`You are about to delete the budget group with ID ${budgetGroupIdForDeletion}`}
        open={deleteBudgetGroupDialogOpen}
        onClose={() => {
          setDeleteBudgetGroupDialogOpen(false)
        }}
      >
        <Button
          onClick={() => {
            setBudgetGroupIdForDeletion("")
            setDeleteBudgetGroupDialogOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          onClick={deleteBudgetGroup}
        >
          Confirm
        </Button>
      </AlertDialog>

      <CrescendaSnackbar
        {...snackbarData}
        onClose={() => {
          setSnackbarData(defaultSnackbarState());
        }}
      />
    </Container>
  )
}
