import { useEffect, useState } from "react"
import { FieldDataType } from "../../data/rules";
import { Paper,  TextField, Button, Stack, Autocomplete } from "@mui/material";
import { dataType2operator, baseFormErrors, booleanValues, filterSkeleton } from "../../data/rules";
import { getFormError } from "../../data/form";
import { formatSelectOption } from "../../utils/format";

import CrescendaSelect from "../CrescendaSelect";

/**
 * Form for creating new rules filters. Currently we handle three
 * types of filters:
 * 
 * - classical: field, operator and value
 * - boolean: field, true/false
 * - enum: field, operator, constrained value
 */
export default function FilterInputCard (props) {
  const [formErrors, setFormErrors] = useState(structuredClone(baseFormErrors))

  const [operators, setOperators] = useState([]);
  const [filter, setFilter] = useState(structuredClone(filterSkeleton));

  const fieldByName = (fieldName) => {
    const prefix = fieldName.split(".")[0];

    // FIXME: Make this an enum please
    if (prefix !== "metrics" && prefix !== "crescenda") {
      // Resource fields
      return props.fields.resource.filter((field) => {
        return field.fieldName === fieldName
      }).pop()
    } else {
      // Metrics and custom fields
      return props.fields[prefix].filter((field) => {
        return field.fieldName === fieldName
      }).pop()
    }
  }

  const fieldNames = props.fields.resource
    .concat(props.fields.metrics)
    .concat(props.fields.crescenda)
    .map((field) => field.fieldName).concat([""])

  const isFieldValid = () => filter.field != null && filter.field !== "";
  const currentField = isFieldValid() ? fieldByName(filter.field) : null
  
  const dataType = currentField ? currentField.dataType : "string"

  useEffect(() => {
    // Select appropriate operators on the basis of the data type
    if (isFieldValid() && currentField) {
      const field = fieldByName(currentField.fieldName);
      setOperators(dataType2operator[field.dataType])
    }
  }, [currentField])

  const validate = () => {
    const validationErrors = structuredClone(baseFormErrors);

    if (filter.field === "") validationErrors.field = getFormError("No field selected");
    if (filter.operator === "") validationErrors.operator = getFormError("No operator selected");

    if (dataType === FieldDataType.ENUM) {
      if (filter.enumValue === "") validationErrors.enumValue = getFormError("Invalid filter value");
    } else if (filter.value === "") {
      validationErrors.value = getFormError("Invalid filter value");
    }

    setFormErrors(validationErrors);

    // Make sure all fields are valid. If even one is false return false
    return Object.keys(validationErrors).map((key) => {
      return validationErrors[key].error;
    }).reduce((prev, current) => !prev && !current, true);
  }

  return (
    <Paper
      elevation={0}
      className="dashboard-card"
      sx={{ padding: 2, marginTop: 1 }}
    >
      <Stack spacing={2}>
        <Autocomplete
          disablePortal
          options={fieldNames}
          fullWidth
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Field"
                error={formErrors.field.error}
                helperText={formErrors.field.message}
              />
            )
          }}
          value={filter.field}
          onChange={(event, field) => {
            setFilter({
              ...filter,
              field: field
            })
          }}
        />

        <CrescendaSelect
          fullWidth
          label="Operator"
          options={operators.map((op) => {
            return {
              label: op,
              value: op
            }
          })}
          value={filter.operator}
          error={formErrors.operator.error}
          helperText={formErrors.operator.message}
          onChange={(operator) => {
            setFilter({
              ...filter,
              operator: operator.value
            })
          }}
        />

        {dataType === FieldDataType.ENUM && (
          <CrescendaSelect
            fullWidth
            label="Value"
            options={currentField.enumValues.map((value) => {
              return formatSelectOption({ value: value })
            })}
            value={filter.enumValue}
            error={formErrors.enumValue.error}
            helperText={formErrors.enumValue.message}
            onChange={(selectedValue) => {
              setFilter({
                ...filter,
                enumValue: selectedValue.value
              })
            }}
          />
        )}

        {dataType === FieldDataType.BOOLEAN && (
          <CrescendaSelect
            fullWidth
            label="Value"
            options={booleanValues.all.map((value) => {
              return formatSelectOption({ value: value })
            })}
            value={filter.value}
            error={formErrors.value.error}
            helperText={formErrors.value.message}
            onChange={(selectedValue) => {
              setFilter({
                ...filter,
                value: selectedValue.value
              })
            }}
          />
        )}

        {(dataType !== FieldDataType.ENUM && dataType !== FieldDataType.BOOLEAN) && (
          <TextField
            error={formErrors.value.error}
            helperText={formErrors.value.message}
            label="Value"
            variant="outlined"
            fullWidth
            value={filter.value}
            onChange={(event) => {
              setFilter({
                ...filter,
                value: event.target.value
              })
            }}
          />
        )}

        <Button
          onClick={() => {
            if (!validate()) return;

            if (dataType === FieldDataType.BOOLEAN) {
              props.addFilter({
                ...filter,
                value: filter.value === booleanValues.true
              });
            } else {
              props.addFilter(filter);
            }
            
            // Clear form
            setOperators([]);
            setFilter(structuredClone(filterSkeleton));
          }}
        >
          Add
        </Button>
      </Stack>
    </Paper>
  );
}