const OperationStatus = {
  PENDING: "pending",
  EXECUTED: "executed",
  // FIXME: Is it the same as in the API?
  IN_EXECUTION: "in_execution",
  FAILED: "failed"
}

const defaultOperationStatus = {
  status: OperationStatus.PENDING,
  error: ""
}

const OperationType = {
  SEARCH_TERMS: "search_terms",
  AD_GROUPS_OPTIMIZATION: "ad_groups_optimization",
  KEYWORDS_PERFORMANCE: "keywords_performance",
  CPC_UPDATE: "cpc_update"
}

// Enumeration used in the search terms review
// operation to identify where the search term
// should be added as negative
const NegativeSearchTermLevel = {
  AD_GROUP: "Ad Group",
  CAMPAIGN: "Campaign",
  ACCOUNT: "Account"
}

// Enumeration used in the search terms review
// operation to classify how a term in table
// should be handled
const SearchTermType = {
  // Add to an account-level ignore list
  // and exclude from future reviews
  IGNORE: "ignore",
  // Add as positive
  POSITIVE: "positive",
  // Add as negative
  NEGATIVE: "negative",
  // Add to an account-level list of terms
  // for which the end customer should be
  // asked (to see whether to add it as
  // positive or negative)
  ASK_CUSTOMER: "ask_customer"
}

function operationPreviewPathElement (operationType) {
  switch (operationType) {
    case OperationType.SEARCH_TERMS:
      return "search-terms"
    case OperationType.AD_GROUPS_OPTIMIZATION:
      return "ad-groups-optimization"
    case OperationType.KEYWORDS_PERFORMANCE:
      return "keywords-performance"
    case OperationType.CPC_UPDATE:
      return "cpc-update"
    default:
      return "invalid"
  }
}

function operationTypeLabel (operationType) {
  switch (operationType) {
    case OperationType.SEARCH_TERMS:
      return "Search Terms Review"
    case OperationType.AD_GROUPS_OPTIMIZATION:
      return "Ad Groups Optimization"
    case OperationType.KEYWORDS_PERFORMANCE:
      return "Keywords Performance"
    case OperationType.CPC_UPDATE:
      return "CPC Update"
    default:
      return "Invalid"
  }
}

function operationLabel (operation) {
  const label = operationTypeLabel(operation.type);

  if (operation.type === OperationType.SEARCH_TERMS) {
    if (operation.isSkag) return `${label} (SKAG)`

    return `${label} (non SKAG)`
  }

  return label;
}

export {
  OperationStatus,
  OperationType,
  SearchTermType,
  NegativeSearchTermLevel,
  operationPreviewPathElement,
  operationLabel,
  operationTypeLabel,
  defaultOperationStatus
};
