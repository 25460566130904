import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box, Button, Paper, TextField, Stack, CircularProgress } from "@mui/material";

import CardLabel from "../components/CardLabel";


export default function TimeframeInput (props) {
  return (
    <Box sx={props.sx}>
      <Stack direction="row" spacing={2}>
        <DesktopDatePicker
          disabled={props.isLoading}
          label="Start Date"
          value={props.startDate}
          onChange={(newDate) => props.onStartDateChange(newDate)}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />

        <DesktopDatePicker
          disabled={props.isLoading}
          label="End Date"
          value={props.endDate}
          onChange={(newDate) => props.onEndDateChange(newDate)}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />

        {props.showRefresh && (
          <Box className="vertical-center">
            {!props.isLoading && (
              <Button sx={{ p: 2 }} onClick={props.onRefresh}>
                Refresh
              </Button>
            )}

            {props.isLoading && (<CircularProgress />)}
          </Box>
        )}
      </Stack>
    </Box>
  )
}