const clusteringTransformers = {
  /**
   * Sorts the clusters and the clusters items of
   * a clustering job by confidence, in descending order
   * 
   * @param {object} item: a clustering job
   * @returns 
   */
  details: (item) => {
    const filterByConfidence = (a, b) => (b.confidence * 100) - (a.confidence * 100);

    const clusters = [...item.clusters];

    clusters.forEach((cluster) => {
      cluster.items.sort(filterByConfidence)
    });

    return {
      id: item.id,
      name: item.name,
      status: item.status,
      progress: item.progress,
      generated: item.generated,
      clusters: clusters.sort(filterByConfidence)
    }
  }
}

export default clusteringTransformers;