import { Link } from "react-router-dom";
import { Typography, Box, Button, Stack, Tooltip } from "@mui/material";

import {
  operationLabel,
  OperationStatus,
  operationPreviewPathElement
} from "../data/enums/operations";

import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/CheckCircle";

import { formatTimestamp } from "../utils/time";


export default function OperationListItem(props) {
  return (
    <Box>
      <Box
        sx={{
          padding: 2,
          paddingTop: 0
        }}
      >
        <Stack direction="row">
          <Box>
            <Typography variant="body1" component="div">
              {operationLabel(props.operation)} - {formatTimestamp(props.operation.generated)}
            </Typography>
            <Typography variant="caption" component="div">
              Operation ID: {props.operation.id}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
            {props.operation.status === OperationStatus.PENDING && (
              <Box sx={{ margin: "auto" }}>
                <Link
                  to={`operations/preview/${operationPreviewPathElement(props.operation.type)}/${props.operation.id}`}
                  className={"operation-preview-link"}
                >
                  <Button variant="text">Preview</Button>
                </Link>
              </Box>
            )}

            {props.operation.status === OperationStatus.EXECUTED && (
              <Tooltip title="Executed">
                <CheckIcon
                  sx={{
                    margin: "auto",
                    color: "success.main"
                  }}
                />
              </Tooltip>
            )}

            {props.operation.status === OperationStatus.FAILED && (
              <Tooltip title="Failed">
                <ErrorIcon
                  sx={{
                    margin: "auto",
                    color: "error.main"
                  }}
                />
              </Tooltip>
            )}
        </Stack>
      </Box>
    </Box>
  );
}