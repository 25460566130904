import "./css/app.css";

import { Link, NavLink, Outlet } from "react-router-dom";
import { DrawerHeader } from "./components/DrawerHeader";
import { routeNames } from "./data/routes";
import { useLocation, matchRoutes } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./api/auth.context";
import { useApiClient } from "./api/client";

import {
    Box,
    List,
    Drawer,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Container
} from "@mui/material";


import HelpIcon from "@mui/icons-material/Help";
import UpdateIcon from "@mui/icons-material/Update";
import ExploreIcon from "@mui/icons-material/Explore";
import SavingsIcon from "@mui/icons-material/Savings";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";

import DrawerNavLink from "./components/DrawerNavLink";

function App() {
  const navigate = useNavigate();
  const { currentUser, updateUser } = useAuth();
  const apiClient = useApiClient();

  const drawerWidth = 288;
  const location = useLocation();

  const [customers, setCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState({
    id: "",
    name: "none"
  });

  // Extract current route information
  const [{ route }] = matchRoutes(routeNames, location);

  useEffect(() => {
    // If the user is not logged in redirect to the login screen
    if (localStorage.user == null) {
      navigate("/login");
    }

    // Make sure the token is not expired. If so logout the user
    apiClient.healthCheck().catch((error) => {
      if (error.response.status === 401 & error.response.data.message === "token expired") {
        updateUser({ token: null, customer: null });
        navigate("/login");
      }
    })

    apiClient.customers().then((apiCustomers) => {
      setCustomers(apiCustomers);

      const currentCustomer = apiCustomers.filter((c) => c.id === currentUser.customer);
      if (currentCustomer.length === 1) setCurrentCustomer(currentCustomer[0]);
    }).catch((error) => {
      console.log(`error fetching customers: ${error}`);
    })
  }, []);

  const handleChangeCustomer = function (event) {
    setCurrentCustomer({ id: event.target.value });
    updateUser({
      ...currentUser,
      customer: event.target.value
    });

    window.location.reload();
  }

  return (
    <Box className="app" sx={{ display: "flex" }}>
      <Drawer
        id="app-drawer"
        elevation={0}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          }
        }}
        variant="permanent"
        anchor="left"
        open={true}
      >
        <DrawerHeader>
          <Link
            to="/"
            className={"drawer-link"}
          >
            <Typography variant="h6" component="div">
              CRESCEN.DA
            </Typography>
          </Link>
        </DrawerHeader>
        <FormControl
          sx={{
            margin: 2
          }}
        >
          <InputLabel>Google Ads Customer</InputLabel>
          <Select
            value={currentCustomer.id}
            label="Google Ads Customer"
            onChange={handleChangeCustomer}
          >
            {customers.map((customer) => {
              return (
                <MenuItem
                  key={customer.id}
                  value={customer.id}>
                    {customer.name} ({customer.id})
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <List sx={{ margin: 2 }}>
          <DrawerNavLink
            to="/"
            icon={<DashboardIcon />}
            title="Dashboard"
          />

          <DrawerNavLink
            to="/budget-pacing"
            icon={<UpdateIcon />}
            title="Budget Pacing"
          />

          <DrawerNavLink
            to="/placements"
            icon={<FeaturedVideoIcon />}
            title="Placements"
          />

          <DrawerNavLink
            to="/campaign-budget-editor"
            icon={<SavingsIcon />}
            title="Budget Editor"
          />

          <DrawerNavLink
            to="/clustering"
            icon={<GroupWorkIcon />}
            title="Clustering"
          />

          <DrawerNavLink
            to="/keywords-extraction"
            icon={<FindInPageIcon />}
            title="Keywords Extraction"
          />

          <DrawerNavLink
            to="/explore"
            icon={<ExploreIcon />}
            title="Explore"
          />

          <DrawerNavLink
            to="/settings"
            icon={<SettingsIcon />}
            title="Settings"
          />

          <DrawerNavLink
            to="/help"
            icon={<HelpIcon />}
            title="Help"
          />
        </List>
      </Drawer>
      <Box
        component="main"
        id="app-content"
        sx={{
          flexGrow: 1,
          padding: 2,
          paddingTop: 5,
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        <Container
          maxWidth={route.maxWidth}
        >
          <Typography
            variant="h4"
            component="div"
          >
            { route.name }
          </Typography>
        </Container>
        <Outlet/>
      </Box>
    </Box>
  );
}

export default App;
