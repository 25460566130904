import { Grid, Paper, Typography } from "@mui/material";

import FilterRow from "./FilterRow";
import FilterInputCard from "./FilterInputCard";

export default function RulesFilters (props) {
  const getFilterString = () => {
    let previousIndentationLevel = 0;
    let outputExpression = "";

    for (let i = 0; i < props.filters.length; i++) {
      const filter = props.filters[i];
      let expression = `${filter.field} ${filter.operator} ${filter.value}`;

      if (filter.indentationLevel > previousIndentationLevel) {
        const indentationDiff = filter.indentationLevel - previousIndentationLevel;
      
        expression = `${'('.repeat(indentationDiff)}${expression}`
      }

      // Add closing )) and logical operator
      const nextFilter = (i < props.filters.length - 1) ? props.filters[i + 1] : null

      if (nextFilter != null) {
        if (nextFilter.indentationLevel < filter.indentationLevel) {
          const indentationDiff = filter.indentationLevel - nextFilter.indentationLevel;

          expression = `${expression}${')'.repeat(indentationDiff)} ${filter.logicalOperator} `
        } else {
          expression = `${expression} ${filter.logicalOperator} `
        }
      } else {
        expression = `${expression}${')'.repeat(filter.indentationLevel)}`
      }

      outputExpression = `${outputExpression}${expression}`;
      previousIndentationLevel = filter.indentationLevel;
    }

    return outputExpression
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FilterInputCard
          fields={props.report.fields}
          addFilter={(filter) => {
            props.addFilter(
              filter.field,
              filter.operator,
              filter.enumValue || filter.value
            )
          }}
        />
      </Grid>

      <Grid item xs={8}>
        <Paper
          elevation={0}
          className="dashboard-card"
          sx={{ padding: 2, marginTop: 1 }}
        >
          <Typography
            variant="body1"
            component="div"
          >
            {getFilterString() || "No filters selected"}
          </Typography>
        </Paper>
        <Paper
          elevation={0}
          className="dashboard-card"
          sx={{
            padding: 2,
            marginTop: 2
          }}
        >
          <Typography
            variant="body1"
            component="div"
          >
            {props.filters.length === 0 && "Selected filters will appear here"}
          </Typography>

          {props.filters.map((filter, index) => {
            return (
              <FilterRow
                key={index}
                filter={filter}
                isTerminal={index === props.filters.length - 1}
                updateFilter={props.updateFilter}
                moveFilter={props.moveFilter}
                deleteFilter={props.deleteFilter}
              />
            )
          })}
        </Paper>
      </Grid>
    </Grid>
  )
}