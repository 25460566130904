import { micros2amount } from "../ads";
import { budgetGroupsPacingMetricsSkeleton } from "../../data/skeletons";

const _ = require("lodash");
const moment = require("moment");


const budgetGroupsTransformer = (item) => {
  return {
    ...item,
    amount: micros2amount(item.amount),
    campaignsCount: item.campaignIds.length,
    startDate: moment(item.startDate).toDate(),
    endDate: moment(item.endDate).toDate()
  }
}

const budgetGroupsPacingMetricsTransformer = (item) => {
  const baseOutput = budgetGroupsTransformer(item);

  if (_.isEmpty(item.metrics)) {
    return {
      ...baseOutput,
      ...structuredClone(budgetGroupsPacingMetricsSkeleton)
    }
  }

  return {
    ...baseOutput,
    budgetStatus: item.metrics.budgetStatus.toLowerCase(),
    timeframeEndDate: moment(item.metrics.reportTimeframeEnd).toDate(),
    generatedOn: moment(item.metrics.generatedOn).toDate(),
    cumulativeCost: item.metrics.reportCumulativeCost,
    forecastTotalSpend: item.metrics.reportForecastTotalSpend,
    percentageForecastTotalSpend: item.metrics.reportPForecastTotalSpend
  }
}

export {
  budgetGroupsTransformer,
  budgetGroupsPacingMetricsTransformer
};