import { Typography } from "@mui/material";


export default function CardLabel(props) {
  return (
    <Typography
      variant="body2"
      component="div"
      sx={{
          fontWeight: 400,
          fontSize: "0.95rem",
          color: "lightText.main",
          ...props.sx
      }}
    >
      {props.text.toUpperCase()}
    </Typography>
  );
}