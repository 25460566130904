import { Grid, Typography } from "@mui/material";


export default function FieldDescriptionRow (props) {
  return (
    <Grid container sx={props.sx}>
      <Grid item xs={4}>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            borderRight: "1px solid",
            borderColor: "border.main",
            padding: 1
          }}
        >
          {props.name}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2" component="div" sx={{ padding: 1 }}>
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  )
}