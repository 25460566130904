import { Typography, Box, Checkbox, Grid } from "@mui/material";

import FieldDescriptionRow from "./FieldDescriptionRow";


export default function FieldDescription (props) {
  const borderBottomStyle = {
    borderBottom: "1px solid",
    borderColor: "border.main"
  }

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderColor: "border.main"
      }}
    >
      <Box sx={{ backgroundColor: "lightBackground.main" }}>
        <Grid container sx={{...borderBottomStyle}}>
          <Grid item xs={9}>
            <Typography variant="subtitle1" component="div" sx={{ padding: 1 }}>
              {props.field.fieldName}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            <Checkbox
              checked={props.selectedFields.has(props.field.fieldName)}
              onChange={(event) => {
                props.handleSelected(
                  props.field.fieldName,
                  event.target.checked
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <FieldDescriptionRow
        name={"Field Description"}
        value={props.field.description}
        sx={borderBottomStyle}
      />

      <FieldDescriptionRow
        name={"Data Type"}
        value={props.field.dataType}
        sx={borderBottomStyle}
      />

      <FieldDescriptionRow
        name={"Repeated"}
        value={props.field.repeated ? "Yes" : "No"}
      />
    </Box>
  )
}