import { doubleFormatter, intFormatter, currencyFormatter } from "../format";


const dataTypeForColumn = (column) => {
  if (column.dataType === "integer" || column.dataType === "double") {
    return "number"
  }

  return column.dataType;
}

const valueFormatterForColumn = (column) => {
  // FIXME: Make the data type an enum
  if (column.dataType === "integer") {
    return intFormatter
  }

  if (column.dataType === "double") {
    return doubleFormatter;
  }

  if (column.dataType === "micros") {
    return currencyFormatter;
  }

  return null;
}

const columnTransformer = (column) => {
  return {
    field: column.field,
    type: dataTypeForColumn(column),
    // 1. Remove prefix
    // 2. Replace ., _ with " "
    // 3. Capitalize each word
    headerName: column.field.split(".").slice(1).join("_").split("_").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(" "),
    width: 250,
    headerAlign: "left",
    valueFormatter: valueFormatterForColumn(column),
    description: column.description
  }
}

export default columnTransformer;