/**
 * Utility function that switches the Google Ads customer
 * based on the request URL
 * 
 * @param {*} user: the app user
 * @param {*} searchParams: react-router-v6 searchParams
 * @param {*} updateUser: updateUser set state callback
 */
function selectCustomer (user, searchParams, updateUser) {
  const requestCustomer = parseInt(searchParams.get("customer"));

  if (!isNaN(requestCustomer) && user.customer !== requestCustomer) {
    updateUser({
      ...user,
      customer: requestCustomer
    });

    window.location.reload();
  }
}

export { selectCustomer };
