import { Stack, Skeleton, Box } from "@mui/material";

import ListItemSkeleton from "./ListItemSkeleton";


export default function IconListItemSkeleton (props) {
  return (
    <Box sx={props.sx}>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            margin: "auto"
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
        <Box
          sx={{
            flexGrow: 1
          }}
        >
          <ListItemSkeleton />
        </Box>
      </Stack>
    </Box>
  )
}