import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiClient } from "../../api/client";
import { useAuth } from "../../api/auth.context";
import { OperationStatus } from "../../data/enums/operations";
import { Container, Button, Box, Paper, Stack, TextField } from "@mui/material";

import CardLabel from "../../components/CardLabel";
import OperationExecutionStatus from "../../views/operations/OperationExecutionStatus";


export default function CreateClusteringJob () {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const { currentUser } = useAuth();

  const [jobName, setJobName] = useState("");
  const [jobData, setJobData] = useState("");

  const [operationStatus, setOperationStatus] = useState({
    status: OperationStatus.PENDING,
    error: ""
  });

  const submitJob = () => {
    const payload = {
      name: jobName,
      inputs: jobData.split("\n").filter((line) => line.length > 0)
    }

    apiClient.submitClusteringJob(currentUser, payload).then((response) => {
      setOperationStatus({
        error: "",
        status: OperationStatus.EXECUTED
      });

      navigate(`/clustering/${response.data.jobId}`)
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setOperationStatus({
        error: message,
        status: OperationStatus.FAILED
      });
    });
  }

  return (
    <Container sx={{ mt: 4 }} maxWidth={false}>
      <CardLabel text={"Job Configuration"} sx={{ mb: 1 }} />

      <OperationExecutionStatus
        successMessage={"Clustering job submitted"}
        failedMessage={"Failed to submit clustering job: "}
        status={operationStatus.status}
        error={operationStatus.error}
        sx={{ p: 0, mb: 2 }}
      />

      <Paper elevation={0} className="dashboard-card">
        <Stack spacing={2} sx={{ p: 2 }}>
          <TextField
            label="Job Name"
            variant="outlined"
            fullWidth
            value={jobName}
            onChange={(event) => setJobName(event.target.value)}
          />

          <TextField
            label="Inputs"
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            value={jobData}
            helperText={"List of inputs. One per line"}
            onChange={(event) => setJobData(event.target.value)}
          />
        </Stack>
      </Paper>

      <Box sx={{ mt: 2, mb: 2, textAlign: "right" }}>
        <Button variant="contained" onClick={submitJob}>Submit</Button>
      </Box>
    </Container>
  );
}