import { Button } from "@mui/material";

import { OperationStatus } from "../../data/enums/operations";


export default function ExecuteButton (props) {
  const label = props.label ? props.label : "Execute";

  const isExecutable = () => {
    const isPending = props.operationStatus === OperationStatus.PENDING;
    const isFailed = props.operationStatus === OperationStatus.FAILED;
    const isExecuted = props.operationStatus === OperationStatus.EXECUTED;

    // Allow multiple runs for executed operations if specified by the user
    return isPending || isFailed || (isExecuted && props.allowsMultiple);
  }

  return (
    <Button
      variant="contained"
      onClick={props.execute}
      disabled={!isExecutable()}
    >
      {label}
    </Button>
  )
}