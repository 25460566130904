import { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useApiClient } from "../../api/client";
import { useAuth } from "../../api/auth.context";

import CardLabel from "../../components/CardLabel";
import OperationPreviewHeader from "./OperationPreviewHeader";

import { Box, Container, Grid, Stepper, Step, StepButton, Tooltip, Button } from "@mui/material";

import {
  searchTermsReviewColumns,
  searchTermsReviewSKAGColumns
} from "../../data/tables/operations";

import operationTransformers from "../../utils/transformers/operations";

import { selectCustomer } from "../../utils/customer";
import { validateSearchTerm } from "../../utils/operations";
import { matchesExclusions } from "../../utils/searchTerms";
import { NegativeSearchTermLevel, SearchTermType } from "../../data/enums/operations";

import { VisibilityOff, TextDecrease, ForwardToInbox } from "@mui/icons-material";

export default function SearchTermsReviewOperationPreview () {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const apiClient = useApiClient();
  const { currentUser, updateUser } = useAuth();

  const operationId = location.pathname.split("/").pop();
  const [operation, setOperation] = useState({
    generated: null,
    isSkag: false,
    id: null
  });

  const columns = operation.isSkag ? searchTermsReviewSKAGColumns : searchTermsReviewColumns;

  // Variables and states that control the positive
  // and negative search terms tables' height
  const rowHeight = 75;
  const maximumVisibleRows = 50;

  const [negativeTableHeight, setNegativeTableHeight] = useState(2400);
  const [positiveTableHeight, setPositiveTableHeight] = useState(2400);

  const [termsCampaigns, setTermsCampaigns] = useState([]);

  const [positiveTerms, setPositiveTerms] = useState([]);
  const [negativeTerms, setNegativeTerms] = useState([]);

  // Properties that control which search terms are
  // currently being shown in the UI
  const [currentStep, setCurrentStep] = useState(0);
  
  const [currentPositiveTerms, setCurrentPositiveTerms] = useState([]);
  const [currentNegativeTerms, setCurrentNegativeTerms] = useState([]);

  // Search terms selected for the operation execution
  const [selectedPositiveTerms, setSelectedPositiveTerms] = useState([]);
  const [selectedNegativeTerms, setSelectedNegativeTerms] = useState([]);

  /**
   * Stores excluded search terms (= selected as negative)
   * to compute selectable positive search terms
   */
  const [termsExclusions, setTermsExclusions] = useState({
    account: [],
    campaign: {},
    adGroup: {},
    customLists: []
  })

  const isTermExcluded = (term) => {
    // Exclusions from custom lists (so non-negative) are treated as exact match exclusions
    if (matchesExclusions(term.searchTerm, termsExclusions.customLists.map((x) => `[${x}]`))) return true;
    if (matchesExclusions(term.searchTerm, termsExclusions.account)) return true;

    const campaignExclusions = termsExclusions.campaign[term.campaignId] || [];
    const adGroupExclusions = termsExclusions.adGroup[term.adGroupId] || []

    if (matchesExclusions(term.searchTerm, campaignExclusions)) return true;
    if (matchesExclusions(term.searchTerm, adGroupExclusions)) return true;

    return false;
  }

  const getUniqueCampaigns = (searchTerms) => {
    const campaigns = [];
    const processedCampaignIds = new Set();
    
    for (let i = 0; i < searchTerms.length; i++) {
      const term = searchTerms[i];

      if (!processedCampaignIds.has(term.campaignId)) {
        campaigns.push({
          id: term.campaignId,
          name: term.campaignName.replaceAll("|", " "),
          completed: false
        });

        processedCampaignIds.add(term.campaignId);
      }
    }

    return campaigns;
  }

  useEffect(() => {
    // Make sure to select the correct customer for the request
    selectCustomer(currentUser, searchParams, updateUser);
  }, [])

  useEffect(() => {
    apiClient.operation(currentUser, operationId).then((operation) => {
      const transformers = operationTransformers.searchTerms[operation.isSkag ? "skag" : "regular"];

      setOperation({
        generated: operation.generated,
        isSkag: operation.isSkag,
        id: operation.id
      });

      const tempPositiveTerms = operation.items.map(transformers.positive);
      const tempNegativeTerms = operation.items.map(transformers.negative);

      const campaigns = getUniqueCampaigns(tempPositiveTerms.concat(tempNegativeTerms));

      setPositiveTerms(tempPositiveTerms);
      setNegativeTerms(tempNegativeTerms);
      setTermsCampaigns(campaigns);

      setCurrentPositiveTerms(tempPositiveTerms.filter((term) => {
        return term.campaignId === campaigns[0].id;
      }));

      setCurrentNegativeTerms(tempNegativeTerms.filter((term) => {
        return term.campaignId === campaigns[0].id;
      }));
    })
  }, [])

  // Reactive hooks that resize the terms tables on load and
  // when the current campaign is changed
  useEffect(() => {
    // Update the positive terms table height
    setPositiveTableHeight(Math.min(currentPositiveTerms.length + 3, maximumVisibleRows) * rowHeight);
  }, [currentPositiveTerms])

  useEffect(() => {
    // Update the negative terms table height
    setNegativeTableHeight(Math.min(currentNegativeTerms.length + 3, maximumVisibleRows) * rowHeight);
  }, [currentNegativeTerms])

  /**
   * Updates the completed status of campaigns, which in
   * turn controls the completion status of the campaign
   * stepper's steps
   */
  const updateCompletedSteps = () => {
    // Extract unique campaign IDs from the selected negative
    // and positive search terms. Search term resource names
    // have the following form:
    //
    // customers/{customer_id}/searchTermViews/{campaign_id}~{ad_group_id}~{URL-base64_search_term}
    const completedCampaignIds = new Set(
      selectedPositiveTerms.concat(selectedNegativeTerms)
      .map((resourceName) => {
        const components = resourceName.split("/");

        return components[components.length - 1];
    }).map((termId) => termId.split("~")[0]));

    // Update the completed status of the campaigns
    setTermsCampaigns(termsCampaigns.map((campaign) => {
      return {
        ...campaign,
        completed: completedCampaignIds.has(campaign.id.toString())
      }
    }))
  }

  const updateCurrentCampaign = (step) => {
    setCurrentStep(step);
    updateCompletedSteps();

    const currentCampaignId = termsCampaigns[step].id;
    const filterLambda = (term) => term.campaignId === currentCampaignId;

    setCurrentPositiveTerms(positiveTerms.filter(filterLambda));
    setCurrentNegativeTerms(negativeTerms.filter(filterLambda));
  }

  useEffect(() => {
    // If the table data is updated fire the negativeKeywordsUpdated
    // callback to update exclusions accordingly
    negativeKeywordsUpdated(selectedNegativeTerms);

    // Also trigger a refresh of the data tables so keyword
    // updates are reflected in the UI. Only trigger updates
    // when campaigns are loaded to avoid errors
    if (termsCampaigns.length > 0) {
      updateCurrentCampaign(currentStep)
    }
  }, [negativeTerms])

  const negativeKeywordsUpdated = function (model) {
    // Retain all negative terms not belonging to the current campaign
    const currentNegativeTermsIds = new Set(currentNegativeTerms.map((term) => term.id));
    const validExistingIds = selectedNegativeTerms.filter((termId) => {
      return !currentNegativeTermsIds.has(termId)
    });

    const negativeIds = new Set(model.concat(validExistingIds));
    const negativeRecords = negativeTerms.filter((term) => {
      return negativeIds.has(term.id);
    })

    const updatedExclusions = {
      account: [],
      campaign: {},
      adGroup: {},
      // Additional exclusions not related to negative keywords, e.g.
      // terms selected for the "ignore" or the "ask customer" lists
      customLists: []
    }

    const removeDoubleQuotes = (term) => {
      // Remove double quotes ("), which indicate phrase match keywords
      return term.replaceAll("\"", "")
    }

    for (let i = 0; i < negativeRecords.length; i++) {
      const record = negativeRecords[i];
      const adGroupId = record.adGroupId;
      const campaignId = record.campaignId;

      if (record.type !== SearchTermType.NEGATIVE) {
        updatedExclusions.customLists.push(removeDoubleQuotes(record.searchTerm));

        continue;
      }

      switch (record.level) {
        case NegativeSearchTermLevel.AD_GROUP:
          if (!(adGroupId in updatedExclusions.adGroup)) {
            updatedExclusions.adGroup[adGroupId] = [];
          }

          updatedExclusions.adGroup[adGroupId].push(removeDoubleQuotes(record.searchTerm));

          break;
        case NegativeSearchTermLevel.CAMPAIGN:
          if (!(campaignId in updatedExclusions.campaign)) {
            updatedExclusions.campaign[campaignId] = [];
          }

          updatedExclusions.campaign[campaignId].push(removeDoubleQuotes(record.searchTerm));

          break;
        case NegativeSearchTermLevel.ACCOUNT:
          updatedExclusions.account.push(removeDoubleQuotes(record.searchTerm));

          break;
      }
    }

    setSelectedNegativeTerms([...negativeIds]);
    setTermsExclusions(updatedExclusions);
  }

  const positiveKeywordsUpdated = function (model) {
    // Retain all positive terms not belonging to the current campaign
    const currentPositiveTermsIds = new Set(currentPositiveTerms.map((term) => term.id));
    const validExistingIds = selectedPositiveTerms.filter((termId) => {
      return !currentPositiveTermsIds.has(termId)
    });

    setSelectedPositiveTerms(model.concat(validExistingIds));
  }

  const navigateToSummary = function () {
    const positiveTermsIds = new Set(selectedPositiveTerms);
    const negativeTermsIds = new Set(selectedNegativeTerms);

    navigate("summary", {
      state: {
        negativeTerms: negativeTerms.filter((term) => negativeTermsIds.has(term.id)),
        positiveTerms: positiveTerms.filter((term) => positiveTermsIds.has(term.id))
      }
    });
  }

  /**
   * Updates the type (negative, ignore, ...) of a
   * negative term
   * 
   * @param {string} termId 
   * @param {SearchTermType} termType 
   */
  const updateNegativeKeywordType = (termId, termType) => {
    // If we are switching to the "negative" term type
    // and the term has one or more warnings while being
    // selected deselect the term
    if (selectedNegativeTerms.includes(termId)) {
      const targetTerm = negativeTerms.filter((term) => term.id === termId).pop()
      const hasWarnings = targetTerm.warnings.length > 0;

      if (termType === SearchTermType.NEGATIVE && hasWarnings) {
        setSelectedNegativeTerms(selectedNegativeTerms.filter((id) => id !== termId))
      }
    }

    // Update the term type
    setNegativeTerms(negativeTerms.map((term) => {
      if (termId === term.id) {
        return {
          ...term,
          type: termType
        }
      }

      return term
    }))
  }

  const negativeKeywordsColumns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Type",
      width: 150,
      getActions: (params) => {
        const activeStyle = {
          color: "primary.main"
        }

        return [
          <Tooltip title="Ignore">
            <GridActionsCellItem
              sx={params.row.type === SearchTermType.IGNORE ? activeStyle : {}}
              icon={<VisibilityOff />}
              label="Ignore"
              onClick={() => {
                updateNegativeKeywordType(params.row.id, SearchTermType.IGNORE)
              }}
            />
          </Tooltip>,
          <Tooltip title="Negative Keyword">
            <GridActionsCellItem
              sx={params.row.type === SearchTermType.NEGATIVE ? activeStyle : {}}
              icon={<TextDecrease />}
              label="Negative Keyword"
              onClick={() => {
                updateNegativeKeywordType(params.row.id, SearchTermType.NEGATIVE)
              }}
            />
          </Tooltip>,
          <Tooltip title="Ask Customer">
            <GridActionsCellItem
              sx={params.row.type === SearchTermType.ASK_CUSTOMER ? activeStyle : {}}
              icon={<ForwardToInbox />}
              label="Ask Customer"
              onClick={() => {
                updateNegativeKeywordType(params.row.id, SearchTermType.ASK_CUSTOMER)
              }}
            />
          </Tooltip>
        ]
      }
    },
    ...columns.negative
  ]

  return (
    <Container
      maxWidth={false}
      sx={{
        marginTop: 4
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <OperationPreviewHeader
            operation={operation}
            title={`Search Terms Review ${operation.isSkag ? "SKAG" : "non SKAG"}`}
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box
            sx={{
              maxWidth: "82vw",
              overflow: "auto",
              overflowY: "hidden",
              margin: "auto"
            }}
          >
            <Stepper
              nonLinear
              alternativeLabel
              activeStep={currentStep}
              sx={{ width: 128 * termsCampaigns.length }}
            >
              {termsCampaigns.map((campaign, index) => (
                <Step key={campaign.id} completed={campaign.completed}>
                  <StepButton color="inherit" onClick={() => updateCurrentCampaign(index)}>
                    {campaign.name}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ mt: 2 }}
        >
          <CardLabel text={"Negative Keywords"} sx={{ marginBottom: 1 }} />
          <Box sx={{ height: negativeTableHeight, width: "100%" }}>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: "allTimeImpressions", sort: "desc" }]
                },
                columns: {
                  columnVisibilityModel: {
                    hasOrganizations: false,
                    organizations: false
                  }
                }
              }}
              headerHeight={72}
              experimentalFeatures={{ newEditingApi: true }}
              rows={currentNegativeTerms}
              columns={negativeKeywordsColumns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              rowHeight={rowHeight}
              checkboxSelection
              disableSelectionOnClick
              selectionModel={selectedNegativeTerms}
              onSelectionModelChange={negativeKeywordsUpdated}
              processRowUpdate={(row) => {
                setNegativeTerms(negativeTerms.map((term) => {
                  if (row.id === term.id) {
                    return {
                      ...term,
                      level: row.level,
                      searchTerm: row.searchTerm,
                      warnings: validateSearchTerm(row.searchTerm)
                    }
                  }

                  return term;
                }));

                return row;
              }}
              isRowSelectable={(params) => {
                const isExcluded = isTermExcluded(params.row);
                const isCurrentlySelected = selectedNegativeTerms.includes(params.id);

                if (isExcluded && !isCurrentlySelected) {
                  return false;
                };

                // Disallow the selection of rows with warnings
                // only for negative terms. Search term lists have
                // no limits in this regard
                if (params.row.type === SearchTermType.NEGATIVE) {
                  return params.row.warnings.length === 0;
                }

                return true;
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 4
          }}
        >
          <CardLabel text={"Positive Keywords"} sx={{ marginBottom: 1 }} />
          <Box sx={{ height: positiveTableHeight, width: "100%" }}>
            <DataGrid
              headerHeight={72}
              experimentalFeatures={{ newEditingApi: true }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "allTimeImpressions", sort: "desc" }]
                },
                columns: {
                  columnVisibilityModel: {
                    hasOrganizations: false,
                    organizations: false
                  }
                }
              }}
              rows={currentPositiveTerms}
              columns={columns.positive}
              pageSize={100}
              rowsPerPageOptions={[100]}
              rowHeight={rowHeight}
              checkboxSelection
              disableSelectionOnClick
              selectionModel={selectedPositiveTerms}
              onSelectionModelChange={positiveKeywordsUpdated}
              isRowSelectable={(params) => {
                if (isTermExcluded(params.row)) return false;

                // Make sure there are no issues with the search term itself
                if (params.row.warnings.length > 0) return false;

                return true;
              }}
              processRowUpdate={(row) => {
                setPositiveTerms(positiveTerms.map((term) => {
                  if (row.id === term.id) {
                    return {
                      ...term,
                      searchTerm: row.searchTerm,
                      warnings: validateSearchTerm(row.searchTerm)
                    }
                  }

                  return term;
                }));

                return row;
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
            marginBottom: 2,
            textAlign: "right"
          }}
        >
          <Button
            variant="contained"
            onClick={navigateToSummary}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
