import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useApiClient } from "../api/client";
import { useAuth } from "../api/auth.context";
import {
  Container,
  Box,
  Alert,
  Paper,
  Stack,
  TextField,
  Button
} from "@mui/material";

import {
  budgetEditorColumns,
  budgetEditorPreviewColumns,
  budgetEditorVisibilityModel
} from "../data/tables/budget";

import {
  budgetAlgorithmList,
  budgetAlgorithmMode,
  budgetAlgorithmOrder,
  budgetAlgorithmMetricList,
  minimumBudgetDelta,
  advertisingChannelTypeList,
  defaultBudgetGroup
} from "../data/budget";

import { optionsObjectToList } from "../utils/data";
import { getFormErrorSkeleton, getFormError } from "../data/form";
import { OperationStatus, defaultOperationStatus } from "../data/enums/operations";
import { addBudget, removeBudget, sortCampaigns } from "../utils/budget";

import CardLabel from "../components/CardLabel";
import ExecuteButton from "./operations/ExecuteButton";
import TimeframeInput from "../components/TimeframeInput";
import CrescendaSelect from "../components/CrescendaSelect";
import DataGridExportToolbar from "../components/DataGridExportToolbar";
import OperationExecutionStatus from "./operations/OperationExecutionStatus";

const moment = require("moment");


export default function CampaignBudgetEditor () {
  const apiClient = useApiClient();
  const { currentUser } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(moment().startOf("day").subtract(2, "weeks").toDate());
  const [endDate, setEndDate] = useState(moment().startOf("day").subtract(1, "days").toDate());
  const [advertisingChannelTypes, setAdvertisingChannelTypes] = useState(advertisingChannelTypeList[0]);

  const [campaigns, setCampaigns] = useState([]);

  const [algorithmMode, setAlgorithmMode] = useState(budgetAlgorithmMode.add);
  const [algorithmOrder, setAlgorithmOrder] = useState(budgetAlgorithmOrder.descending);
  const [algorithmMetric, setAlgorithmMetric] = useState(budgetAlgorithmMetricList[0]);

  const [budgetDelta, setBudgetDelta] = useState(0);
  const [budgetDeltaError, setBudgetDeltaError] = useState(getFormErrorSkeleton());

  const [budgetGroups, setBudgetGroups] = useState([defaultBudgetGroup()]);
  const [currentBudgetGroup, setCurrentBudgetGroup] = useState(defaultBudgetGroup());

  const [updatedCampaigns, setUpdatedCampaigns] = useState([]);
  const [selectedUpdatedCampaigns, setSelectedUpdatedCampaigns] = useState([]);

  const [operationStatus, setOperationStatus] = useState(structuredClone(defaultOperationStatus));

  useEffect(() => {
    apiClient.budgetGroups(currentUser).then((groups) => {
      setBudgetGroups([
        ...groups,
        defaultBudgetGroup()
      ]);
    });
  }, [])

  const refreshBudgets = () => {
    setIsLoading(true);

    // Add the budget group unless the default
    // "All campaigns" group was chosen
    const budgetGroupId = (
      currentBudgetGroup.id === defaultBudgetGroup().id
    ) ? null : currentBudgetGroup.id;

    apiClient.campaignBudgets(
      currentUser,
      moment(startDate).startOf("day").toDate(),
      moment(endDate).endOf("day").toDate(),
      advertisingChannelTypes.value,
      budgetGroupId
    ).then((response) => {
      setIsLoading(false);
      setCampaigns(response.data.campaigns);
    });
  }

  const allocateBudget = () => {
    const parsedBudgetDelta = parseFloat(budgetDelta);

    if (parsedBudgetDelta < 0) {
      setBudgetDeltaError(getFormError("budget delta must be > 0"));
      return;
    } else if (isNaN(parsedBudgetDelta)) {
      setBudgetDeltaError(getFormError("invalid budget delta"));
      return;
    }

    setBudgetDeltaError(getFormErrorSkeleton());

    const sortedCampaigns = sortCampaigns(
      campaigns,
      algorithmMetric.value,
      algorithmOrder.value === budgetAlgorithmOrder.ascending.value
    );

    if (algorithmMode.value === budgetAlgorithmMode.add.value) {
      const updatedCampaigns = addBudget(
        sortedCampaigns,
        parseFloat(parsedBudgetDelta)
      );

      setUpdatedCampaigns(updatedCampaigns);
    } else {
      const updatedCampaigns = removeBudget(
        sortedCampaigns,
        parseFloat(parsedBudgetDelta)
      );

      setUpdatedCampaigns(updatedCampaigns);
    }
  }

  const selectedBudgetsUpdated = (model) => {
    setSelectedUpdatedCampaigns(model);
  }

  const execute = () => {
    setOperationStatus({
      ...operationStatus,
      status: OperationStatus.IN_EXECUTION
    });

    const selectedRowIds = new Set(selectedUpdatedCampaigns);
    const targetBudgets = updatedCampaigns.filter((campaign) => {
      return selectedRowIds.has(campaign.id)
    });

    apiClient.updateCampaignBudgets(currentUser, targetBudgets).then((response) => {
      setOperationStatus({
        ...operationStatus,
        status: OperationStatus.EXECUTED
      });
    }).catch((error) => {
      if (error.response) {
        setOperationStatus({
          status: OperationStatus.FAILED,
          error: error.response.data.message
        });
      } else {
        setOperationStatus({
          status: OperationStatus.FAILED,
          error: error.toString()
        });
      }
    });
  }

  const timeframeDays = moment(endDate).diff(moment(startDate), "days");

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <CardLabel text={"Campaign Selection"} sx={{ mb: 1, mt: 2 }} />
      <Paper elevation={0} className="dashboard-card" sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" spacing={2}>
          <CrescendaSelect
            fullWidth
            label="Budget Group"
            value={currentBudgetGroup.id}
            options={budgetGroups.map((group) => {
              return {
                label: group.name,
                value: group.id
              }
            })}
            onChange={(selected) => {
              const budgetGroup = budgetGroups.filter((group) => {
                return group.id === selected.value
              }).pop();

              setCurrentBudgetGroup(budgetGroup);
            }}
          />

          <CrescendaSelect
            fullWidth
            label="Advertising Channel Type"
            options={advertisingChannelTypeList}
            value={advertisingChannelTypes.value}
            onChange={(value) => {
              setAdvertisingChannelTypes(value)
            }}
          />
        </Stack>
      </Paper>

      <CardLabel text={"Timeframe"} sx={{ mb: 1, mt: 2 }} />
      <Paper elevation={0} className="dashboard-card" sx={{ p: 2, mb: 2 }}>
        <TimeframeInput
          showRefresh={true}
          isLoading={isLoading}
          startDate={startDate}
          endDate={endDate}
          onRefresh={refreshBudgets}
          onStartDateChange={(date) => setStartDate(date.toDate())}
          onEndDateChange={(date) => setEndDate(date.toDate())}
        />
      </Paper>

      {(timeframeDays > 30) && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          You should only consider timeframes of 30 days or less. The current timeframes considers {timeframeDays} days
        </Alert>
      )}

      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          headerHeight={76}
          columns={budgetEditorColumns}
          rows={campaigns}
          pageSize={10}
          rowHeight={125}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          initialState={{
            columns: {
              columnVisibilityModel: budgetEditorVisibilityModel
            }
          }}
        />
      </Box>

      <CardLabel text={"Algorithm Selection"} sx={{ mb: 1, mt: 2 }} />
      <Paper elevation={0} className="dashboard-card" sx={{ p: 2 }}>
        <Stack direction={"row"} spacing={2}>
          {/* Handle onChange events when more algorithm are added */}
          <CrescendaSelect
            fullWidth
            label="Algorithm"
            options={budgetAlgorithmList}
            value={budgetAlgorithmList[0].value}
          />

          <CrescendaSelect
            fullWidth
            label="Metric"
            options={budgetAlgorithmMetricList}
            value={algorithmMetric.value}
            onChange={(value) => {
              setAlgorithmMetric(value)
            }}
          />

          <CrescendaSelect
            fullWidth
            label="Ordering"
            options={optionsObjectToList(budgetAlgorithmOrder)}
            value={algorithmOrder.value}
            onChange={(value) => {
              setAlgorithmOrder(value)
            }}
          />

          <CrescendaSelect
            fullWidth
            label="Mode"
            options={optionsObjectToList(budgetAlgorithmMode)}
            value={algorithmMode.value}
            onChange={(value) => {
              setAlgorithmMode(value)
            }}
          />

          <TextField
            fullWidth
            type="number"
            label="Daily Budget Delta"
            variant="outlined"
            value={budgetDelta}
            error={budgetDeltaError.error}
            helperText={budgetDeltaError.message}
            onChange={(event) => {
              setBudgetDelta(event.target.value)
            }}
          />

          <Box className="vertical-center">
            <Button sx={{ p: 2 }} onClick={allocateBudget}>
              Preview
            </Button>
          </Box>
        </Stack>
      </Paper>

      <Box sx={{
        mt: 2,
        height: 600,
        width: "100%",
        "& .budget-updated-row": {
          bgcolor: (theme) => theme.palette.warning.background,
          '&:hover': {
            bgcolor: (theme) => `${theme.palette.warning.background} !important`,
          },
        }
      }}>
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          columns={budgetEditorPreviewColumns}
          rows={updatedCampaigns}
          pageSize={10}
          rowHeight={125}
          headerHeight={76}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          components={{ Toolbar: DataGridExportToolbar }}
          onSelectionModelChange={selectedBudgetsUpdated}
          initialState={{
            columns: {
              columnVisibilityModel: budgetEditorVisibilityModel
            }
          }}
          getRowClassName={(params) => {
            const budgetDiff = Math.abs(
              params.row.budget - params.row.proposedBudget
            );

            return budgetDiff >= minimumBudgetDelta ? "budget-updated-row" : "";
          }}
          processRowUpdate={(row) => {
            setUpdatedCampaigns(updatedCampaigns.map((campaign) => {
              if (row.id === campaign.id) {
                return {
                  ...campaign,
                  proposedBudget: parseFloat(row.proposedBudget)
                }
              }

              return campaign
            }));

            return row;
          }}
        />
      </Box>

      <Box>
        <OperationExecutionStatus
          status={operationStatus.status}
          error={operationStatus.error}
        />
      </Box>

      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          textAlign: "right"
        }}
      >
        <ExecuteButton
          execute={execute}
          operationStatus={operationStatus.status}
        />
      </Box>
    </Container>
  );
}