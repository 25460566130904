import { Stack, Paper } from "@mui/material";

import ErrorLogRow from "./ErrorLogRow";
import SuccessLogRow from "./SuccessLogRow";
import PartialFailureLogRow from "./PartialFailureLogRow";


export default function ExecutionLog (props) {
  const failed = props.failed || [];
  const executed = props.executed || [];
  
  console.log(executed);

  return (
    <Paper
      elevation={0}
      className="dashboard-card log-card"
      sx={{ padding: 2 }}
    >
      <Stack spacing={1}>
        {executed.map((item) => {
          if (item.partialFailures.length === 0) {
            return (<SuccessLogRow key={item.item} result={item} />)
          } else {
            return (<PartialFailureLogRow key={item.item} result={item} />)
          }
        })}

        {failed.map((item) => <ErrorLogRow key={item.item} result={item} />)}
      </Stack>
    </Paper>
  );
}