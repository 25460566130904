import { Typography, Box } from "@mui/material";
import { formatTimestamp } from "../../utils/time";


export default function OperationPreviewHeader (props) {
  return (
    <Box>
      <Typography variant="h6" component="div">
        {props.title} - {props.operation.generated && formatTimestamp(props.operation.generated)}
      </Typography>
      <Typography variant="body1" component="div">
        Operation ID: {props.operation.id}
      </Typography>
    </Box>
  )
}