import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useSearchParams } from "react-router-dom";
import { useApiClient } from "../../api/client";
import { useAuth } from "../../api/auth.context";
import { selectCustomer } from "../../utils/customer";

import ExecuteButton from "./ExecuteButton";
import CardLabel from "../../components/CardLabel";
import OperationPreviewHeader from "./OperationPreviewHeader";
import OperationExecutionStatus from "./OperationExecutionStatus";
import AdGroupsOptimizationMetrisCard from "./AdGroupsOptimizationMetrisCard";

import { OperationStatus } from "../../data/enums/operations";
import { adGroupsOptimizationColumns } from "../../data/tables/operations";

import operationTransformers from "../../utils/transformers/operations";


export default function AdGroupsOptimizationOperationPreview() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const apiClient = useApiClient();
  const { currentUser, updateUser } = useAuth();

  const operationId = location.pathname.split("/").pop();
  const [operation, setOperation] = useState({
    generated: null,
    isSkag: false,
    id: null
  });

  const [operationStatus, setOperationStatus] = useState({
    status: OperationStatus.PENDING,
    error: ""
  });

  const [exactMatchAdGroups, setExactMatchAdGroups] = useState([]);
  const [broadMatchAdGroups, setBroadMatchAdGroups] = useState([]);

  const [selectedExactMatchAdGroups, setSelectedExactMatchAdGroups] = useState([]);
  const [selectedBroadMatchAdGroups, setSelectedBroadMatchAdGroups] = useState([]);

  const [metrics, setMetrics] = useState({
    exact: [],
    broad: []
  })

  useEffect(() => {
    // Make sure to select the correct customer for the request
    selectCustomer(currentUser, searchParams, updateUser);
  }, [])

  useEffect(() => {
    apiClient.operation(currentUser, operationId).then((operation) => {
      const items = operation.items.map(operationTransformers.adGroupsPerformance);

      // Exact match ad groups have (E) in their name. Other ad groups
      // are considered to be broad match (P)
      const exactMatchAdGroups = items.filter((item) => item.adGroup.includes("(E)"));
      const broadMatchAdGroups = items.filter((item) => !item.adGroup.includes("(E)"));

      setOperation({
        generated: operation.generated,
        isSkag: operation.isSkag,
        id: operation.id
      });

      setMetrics(operation.metrics);

      setExactMatchAdGroups(exactMatchAdGroups);
      setBroadMatchAdGroups(broadMatchAdGroups);
    })
  }, [])

  const execute = function () {
    setOperationStatus({
      ...operationStatus,
      status: OperationStatus.IN_EXECUTION
    });

    const payload = {
      // Merge exact and broad ad groups
      targets: selectedExactMatchAdGroups.concat(selectedBroadMatchAdGroups)
    };

    apiClient.executeOperation(currentUser, operationId, payload).then(() => {
      setOperationStatus({
        ...operationStatus,
        status: OperationStatus.EXECUTED
      });
    }).catch((error) => {
      if (error.response) {
        setOperationStatus({
          status: OperationStatus.FAILED,
          error: error.response.data.message
        });
      }
    });
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        marginTop: 4
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <OperationPreviewHeader
            operation={operation}
            title={"Ad Groups Optimization"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 4
          }}
        >
          <CardLabel text={"Broad Match Ad Groups (P)"} sx={{ marginBottom: 1 }} />
          <AdGroupsOptimizationMetrisCard
            metrics={metrics.broad}
            sx={{
              marginBottom: 2
            }}
          />
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              headerHeight={64}
              rows={broadMatchAdGroups}
              columns={adGroupsOptimizationColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(model) => {
                setSelectedBroadMatchAdGroups(model);
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 4
          }}
        >
          <CardLabel text={"Exact Match Ad Groups (E)"} sx={{ marginBottom: 1 }} />
          <AdGroupsOptimizationMetrisCard
            metrics={metrics.exact}
            sx={{
              marginBottom: 2
            }}
          />
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              headerHeight={64}
              rows={exactMatchAdGroups}
              columns={adGroupsOptimizationColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(model) => {
                setSelectedExactMatchAdGroups(model);
              }}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <OperationExecutionStatus
            status={operationStatus.status}
            error={operationStatus.error}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
            marginBottom: 2,
            textAlign: "right"
          }}
        >
          <ExecuteButton execute={execute} operationStatus={operationStatus.status} />
        </Grid>
      </Grid>
    </Container>
  );
}
