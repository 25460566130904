import { Grid, Paper } from "@mui/material";

import MetricListItem from "../../components/MetricListItem";
import IconListItemSkeleton from "../../components/IconListItemSkeleton";


export default function AdGroupsOptimizationMetrisCard (props) {
  return (
    <Paper
      elevation={0}
      className="dashboard-card"
      sx={{
        paddingTop: 2,
        ...props.sx
      }}
    >
      <Grid container>
        {props.metrics.length === 0 && (
          Array(9).fill().map((x, index) => {
            return (
              <Grid item xs={3} key={index}>
                <IconListItemSkeleton
                  sx={{
                    padding: 2,
                    paddingTop: 0
                  }}
                />
              </Grid>
            )
          })
        )}
        {props.metrics.map((metric) => {
          return (
            <Grid item xs={3} key={metric.name}>
              <MetricListItem
                metric={metric.label}
                totalObservations={metric.total}
                averageObservations={metric.average}
                valid={metric.average > 0.5}
              />
            </Grid>
          )
        })}
      </Grid>
    </Paper>
  )
}