import {
  Box,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import FieldDescription from "./FieldDescription";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export default function FieldDescriptionAccordion (props) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{props.title} ({props.fields.length})</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {props.fields.map((field) => {
            return (
              <FieldDescription
                field={field}
                key={field.name}
                handleSelected={props.handleSelected}
                selectedFields={props.selectedFields}
              />
            )
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}