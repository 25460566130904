/**
 * Adds the given budget delta to campaigns
 * sequentially in place.
 * 
 * @param {Array}   campaigns     array of campaigns whose budget
 *                                should be updated
 * @param {Number}  budgetDelta   the budget to be added
 * @returns {Array}               the updated campaigns
 */
function addBudget (campaigns, budgetDelta) {
  var availableBudget = budgetDelta;

  for (let i = 0; i < campaigns.length; i++) {
    const campaign = campaigns[i];
    const capacity = campaign.availableDailyCapacity;

    if (capacity > 0 && availableBudget > 0) {
      if (availableBudget > capacity) {
        // Fill the campaign and remove the budget
        // from the available amount
        availableBudget -= capacity;
        campaign.proposedBudget = campaign.budget + capacity;
      } else {
        // This campaign has a larger capacity than
        // the available additional budget. Add the
        // remaining budget and terminate the loop
        campaign.proposedBudget = campaign.budget + availableBudget;
        availableBudget = 0;
      }
    } else {
      campaign.proposedBudget = campaign.budget;
    }
  }

  return campaigns;
}

/**
 * Removes the given budget delta from campaigns
 * sequentially in place.
 * 
 * @param {Array}   campaigns     array of campaigns whose budget
 *                                should be updated
 * @param {Number}  budgetDelta   the budget to be removed
 * @returns {Array}               the updated campaigns
 */
function removeBudget (campaigns, budgetDelta) {
  var budgetToRemove = budgetDelta;

  for (let i = 0; i < campaigns.length; i++) {
    const campaign = campaigns[i];
  
    if (campaign.budget > 0 && budgetToRemove > 0) {
      const campaignBudgetDelta = Math.min(campaign.budget, budgetToRemove);

      campaign.proposedBudget = campaign.budget - campaignBudgetDelta;
      budgetToRemove -= campaignBudgetDelta;
    } else {
      campaign.proposedBudget = campaign.budget;
    }
  }

  return campaigns;
}

/**
 * Creates a deep clone of the given campaigns and
 * sorts them by a metric (and order)
 * 
 * @param {Array}   campaigns     the campaigns to be sorted
 * @param {String}  metric        the budget to be removed
 * @returns {Array}               the sorted
 */
function sortCampaigns (campaigns, metric, ascending) {
  const sortedCampaigns = structuredClone(campaigns);

  // Always sort items in ascending order
  sortedCampaigns.sort((a, b) => {
    if (a[metric] > b[metric]) {
      // a after b
      return 1;
    } else if (a[metric] < b[metric]) {
      // a before b
      return -1;
    }

    // keep original order
    return 0;
  });

  if (!ascending) {
    // Sort in descending order
    sortedCampaigns.reverse();
  }

  return sortedCampaigns;
}

export {
  addBudget,
  removeBudget,
  sortCampaigns
}