/**
 * This file contains column definitions for data tables
 * used in operation preview views
 */
import { NegativeSearchTermLevel } from "../enums/operations";
import { currencyFormatter, percentageFormatter, intFormatter, formatSnakeCase } from "../../utils/format";
import { enumOptionLabels } from "../../data/enums/generic";
import { renderTagsCell } from "./cells";


// Keywords Performance - Broad Match
const keywordsPerformanceBroadMatchColumns = [
  { field: "keyword", headerName: "Keyword", width: 200, type: "string" },
  {
    field: "matchType",
    headerName: "Match Type",
    width: 75,
    type: "singleSelect",
    valueOptions: enumOptionLabels.matchType
  },
  { field: "adGroup", headerName: "Ad Group", width: 200, type: "string" },
  { field: "campaignName", headerName: "Campaign", width: 200, type: "string" },
  { field: "daysSinceCreation", headerName: "Days Since Creation", width: 100, type: "number", headerAlign: "left" },
  {
    field: "optimizationReason",
    headerName: "Optimization Reason",
    width: 225,
    type: "singleSelect",
    valueOptions: enumOptionLabels.optimizationReason
  },
  { field: "clicks", headerName: "Clicks", width: 60, type: "number", headerAlign: "left", valueFormatter: intFormatter },
  { field: "conversions", headerName: "Conversions", type: "number", headerAlign: "left" },
  { field: "conversionValue", headerName: "Conversion Value", width: 100, valueFormatter: currencyFormatter, type: "number", headerAlign: "left" },
  { field: "cost", headerName: "Cost", valueFormatter: currencyFormatter, type: "number", headerAlign: "left" },
  { field: "conversionValueCost", headerName: "Conversion Value / Cost", width: 100, valueFormatter: percentageFormatter, type: "number", headerAlign: "left" },
  { field: "costConversion", headerName: "Cost / Conversions", width: 100, valueFormatter: currencyFormatter, type: "number", headerAlign: "left" }
];

// Keywords Performance - Exact Match
const keywordsPerformanceExactMatchColumns = [
  { field: "keyword", headerName: "Keyword", width: 200, type: "string" },
  { field: "adGroup", headerName: "Ad Group", width: 225, type: "string" },
  { field: "campaignName", headerName: "Campaign", width: 225, type: "string" },
  { field: "daysSinceCreation", headerName: "Days Since Creation", width: 100, type: "number", headerAlign: "left" },
  {
    field: "optimizationReason",
    headerName: "Optimization Reason",
    width: 225,
    type: "singleSelect",
    valueOptions: enumOptionLabels.optimizationReason
  },
  { field: "clicks", headerName: "Clicks", width: 60, type: "number", headerAlign: "left", valueFormatter: intFormatter },
  { field: "conversions", headerName: "Conversions", type: "number", headerAlign: "left" },
  { field: "conversionValue", headerName: "Conversion Value", width: 100, valueFormatter: currencyFormatter, type: "number", headerAlign: "left" },
  { field: "cost", headerName: "Cost", valueFormatter: currencyFormatter, type: "number", headerAlign: "left" },
  { field: "conversionValueCost", headerName: "Conversion Value / Cost", width: 100, valueFormatter: percentageFormatter, type: "number", headerAlign: "left" },
  { field: "costConversion", headerName: "Cost / Conversions", width: 100, valueFormatter: currencyFormatter, type: "number", headerAlign: "left" }
];

// Ad Groups Optimization
const adGroupsOptimizationColumns = [
  { field: "adGroup", headerName: "Ad Group", width: 200, type: "string" },
  { field: "campaignName", headerName: "Campaign", width: 300, type: "string" },
  { field: "daysSinceCreation", headerName: "Days Since Creation", width: 100, type: "number", headerAlign: "left" },
  {
    field: "optimizationReason",
    headerName: "Optimization Reason",
    width: 300,
    type: "singleSelect",
    valueOptions: enumOptionLabels.optimizationReason
  },
  { field: "clicks", headerName: "Clicks", width: 60, type: "number", headerAlign: "left", valueFormatter: intFormatter },
  { field: "conversions", headerName: "Conversions", type: "number", headerAlign: "left" },
  { field: "conversionValue", headerName: "Conversion Value", width: 100, valueFormatter: currencyFormatter, type: "number", headerAlign: "left" },
  { field: "cost", headerName: "Cost", valueFormatter: currencyFormatter, type: "number", headerAlign: "left" },
  { field: "conversionValueCost", headerName: "Conversion Value / Cost", width: 100, valueFormatter: percentageFormatter, type: "number", headerAlign: "left" },
  { field: "costConversion", headerName: "Cost / Conversions", width: 100, valueFormatter: currencyFormatter, type: "number", headerAlign: "left" }
];

// Search Terms Review - SKAG
const searchTermsReviewSKAGColumns = {
  negative: [
    { field: "searchTerm", headerName: "Search Term", width: 250, editable: true, type: "string" },
    {
      field: "level",
      headerName: "Negative Level",
      type: "singleSelect",
      valueOptions: [
        NegativeSearchTermLevel.CAMPAIGN,
        NegativeSearchTermLevel.AD_GROUP,
        NegativeSearchTermLevel.ACCOUNT,
      ],
      width: 100,
      editable: true
    },
    { field: "hasOrganizations", headerName: "Has Organizations", width: 125, type: "boolean" },
    { field: "organizations", headerName: "Organizations", width: 175, type: "string", renderCell: renderTagsCell },
    { field: "daysSinceCreation", headerName: "Days Since Creation", width: 100, type: "number", headerAlign: "left" },
    { field: "adGroup", headerName: "Ad Group", width: 150, type: "string" },
    { field: "campaignName", headerName: "Campaign", width: 250, type: "string" },
    { field: "weekImpressions", headerName: "Impressions (last 7 days)", width: 100, type: "number", headerAlign: "left" },
    { field: "allTimeImpressions", headerName: "Impressions (all time)", width: 100, type: "number", headerAlign: "left" },
    { field: "warnings", headerName: "Warnings", width: 200, type: "string" }
  ],
  positive: [
    { field: "searchTerm", headerName: "Search Term", width: 250, editable: false, type: "string" },
    { field: "hasOrganizations", headerName: "Has Organizations", width: 125, type: "boolean" },
    { field: "organizations", headerName: "Organizations", width: 175, type: "string", renderCell: renderTagsCell },
    { field: "daysSinceCreation", headerName: "Days Since Creation", width: 100, type: "number", headerAlign: "left" },
    { field: "adGroup", headerName: "Ad Group", width: 150, type: "string" },
    { field: "campaignName", headerName: "Campaign", width: 250, type: "string" },
    { field: "weekImpressions", headerName: "Impressions (last 7 days)", width: 100, type: "number", headerAlign: "left" },
    { field: "allTimeImpressions", headerName: "Impressions (all time)", width: 100, type: "number", headerAlign: "left" },
    { field: "warnings", headerName: "Warnings", width: 200, type: "string" }
  ]
};

// Search Terms Review - non SKAG
const searchTermsReviewColumns = {
  negative: [
    { field: "searchTerm", headerName: "Search Term", width: 250, editable: true, type: "string" },
    {
      field: "level",
      headerName: "Negative Level",
      type: "singleSelect",
      valueOptions: [
        NegativeSearchTermLevel.CAMPAIGN,
        NegativeSearchTermLevel.AD_GROUP,
        NegativeSearchTermLevel.ACCOUNT,
      ],
      width: 100,
      editable: true
    },
    { field: "hasOrganizations", headerName: "Has Organizations", width: 125, type: "boolean" },
    { field: "organizations", headerName: "Organizations", width: 175, type: "string", renderCell: renderTagsCell },
    { field: "daysSinceCreation", headerName: "Days Since Creation", width: 100, type: "number", headerAlign: "left" },
    { field: "keyword", headerName: "Original Keyword", width: 150, type: "string" },
    {
      field: "matchType",
      headerName: "Original Keyword Match Type",
      width: 150,
      type: "singleSelect",
      valueOptions: enumOptionLabels.matchType
    },
    { field: "adGroup", headerName: "Ad Group", width: 150, type: "string" },
    { field: "campaignName", headerName: "Campaign", width: 250, type: "string" },
    { field: "weekImpressions", headerName: "Impressions (last 7 days)", width: 100, type: "number", headerAlign: "left" },
    { field: "allTimeImpressions", headerName: "Impressions (all time)", width: 100, type: "number", headerAlign: "left" },
    { field: "warnings", headerName: "Warnings", width: 200, type: "string" }
  ],
  positive: [
    { field: "searchTerm", headerName: "Search Term", width: 250, editable: true, type: "string" },
    { field: "hasOrganizations", headerName: "Has Organizations", width: 125, type: "boolean" },
    { field: "organizations", headerName: "Organizations", width: 175, type: "string", renderCell: renderTagsCell },
    { field: "daysSinceCreation", headerName: "Days Since Creation", width: 100, type: "number", headerAlign: "left" },
    { field: "keyword", headerName: "Original Keyword", width: 150, type: "string" },
    {
      field: "matchType",
      headerName: "Original Keyword Match Type",
      width: 150,
      type: "singleSelect",
      valueOptions: enumOptionLabels.matchType
    },
    { field: "adGroup", headerName: "Ad Group", width: 150, type: "string" },
    { field: "campaignName", headerName: "Campaign", width: 250, type: "string" },
    { field: "weekImpressions", headerName: "Impressions (last 7 days)", width: 100, type: "number", headerAlign: "left" },
    { field: "allTimeImpressions", headerName: "Impressions (all time)", width: 100, type: "number", headerAlign: "left" },
    { field: "warnings", headerName: "Warnings", width: 200, type: "string" }
  ]
}

const searchTermsReviewSummaryColumns = [
  { field: "searchTerm", headerName: "Search Term", width: 250, type: "string" },
  { field: "campaignName", headerName: "Campaign", width: 350, type: "string" },
  {
    field: "displayTermType",
    headerName: "Type",
    type: "singleSelect",
    valueOptions: enumOptionLabels.searchTermType,
    valueGetter: formatSnakeCase
  },
  { field: "level", headerName: "Negative Level", type: "string", width: 150 }
]

// Keywords Bid Optimization
const keywordsBidOptimizationColumns = [
  { field: "keyword", headerName: "Keyword", width: 250, type: "string" },
  { field: "adGroup", headerName: "Ad Group", width: 250, type: "string" },
  { field: "campaignName", headerName: "Campaign", width: 350, type: "string" },
  { field: "maxCpc", headerName: "Previous Max CPC", width: 150, valueFormatter: currencyFormatter, type: "number" },
  { field: "proposedMaxCpc", headerName: "Proposed Max CPC", width: 150, valueFormatter: currencyFormatter, type: "number" },
  { field: "conversions", headerName: "Conversions", type: "number" },
  { field: "conversionValueCost", headerName: "Conversion Value / Cost", width: 200, type: "number" },
  { field: "topImpressionPercentage", headerName: "Top Impression Percentage", width: 200, valueFormatter: percentageFormatter, type: "number" },
  { field: "absoluteTopImpressionPercentage", headerName: "Absolute Top Impression Percentage", width: 250, valueFormatter: percentageFormatter, type: "number" }
]

export {
  keywordsPerformanceBroadMatchColumns,
  keywordsPerformanceExactMatchColumns,
  adGroupsOptimizationColumns,
  searchTermsReviewSKAGColumns,
  searchTermsReviewColumns,
  keywordsBidOptimizationColumns,
  searchTermsReviewSummaryColumns
};
