import React from 'react';
import ReactDOM from 'react-dom/client';

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

import { alpha } from "@mui/material/styles";
import { blue, green, red, amber } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AppRoutes } from "./routes";
import { AuthProvider, getLocalUser } from "./api/auth.context";

import reportWebVitals from './reportWebVitals';

import "moment/locale/en-gb";


TimeAgo.addDefaultLocale(en);

const user = getLocalUser();
const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    primary: {
      main: blue[500]
    },
    success: {
      main: green["A700"],
      contrastText: "#FFFFFF"
    },
    error: {
      main: red["A400"]
    },
    warning: {
      main: amber[500],
      background: alpha(amber[500], 0.05),
    },
    border: {
      main: "#E0E0E0"
    },
    lightText: {
      main: "rgba(0, 0, 0, 0.64)"
    },
    lightBackground: {
      main: "rgba(0, 0, 0, 0.03)"
    }
  },
});

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-GB"}>
      <ThemeProvider theme={theme}>
        <AuthProvider user={user}>
          <AppRoutes />
        </AuthProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
