import { Stack, Typography } from "@mui/material";

import ErrorIcon from "@mui/icons-material/Error";


export default function ErrorLogRow (props) {
  return (
    <Stack direction={"row"}>
      <ErrorIcon
        sx={{
          margin: "auto",
          color: "error.main",
          marginRight: 1
        }}
      />
      <Typography
        className="log-text"
        variant="body2"
        component="div"
        gutterBottom
        sx={{ flexGrow: 1, marginTop: "auto", marginBottom: "auto" }}
      >
        {props.result.item}: failed ({props.result.error})
      </Typography>
    </Stack>
  )
}