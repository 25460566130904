import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useApiClient } from "../api/client";
import { useAuth } from "../api/auth.context";
import { Box, Grid, Container, Paper } from "@mui/material";

import CardLabel from "../components/CardLabel";
import ExecuteButton from "./operations/ExecuteButton";
import placementColumns from "../data/tables/placements";
import TimeframeInput from "../components/TimeframeInput";
import placementsTransormer from "../utils/transformers/placements";
import OperationExecutionStatus from "./operations/OperationExecutionStatus";

import { OperationStatus } from "../data/enums/operations";

const moment = require("moment");


export default function Placements () {
  const apiClient = useApiClient();
  const { currentUser } = useAuth();

  const [startDate, setStartDate] = useState(moment().startOf("year").toDate());
  const [endDate, setEndDate] = useState(moment().startOf("day").toDate());

  const [placements, setPlacements] = useState([]);
  const [selectedPlacements, setSelectedPlacements] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [operationStatus, setOperationStatus] = useState({
    status: OperationStatus.PENDING,
    error: ""
  });

  const refreshPlacements = () => {
    setIsLoading(true);

    setPlacements([]);
    setSelectedPlacements([]);

    setOperationStatus({
      ...operationStatus,
      status: OperationStatus.PENDING
    });

    apiClient.placements(
      currentUser,
      startDate,
      endDate
    ).then((placements) => {
      setPlacements(placements.map(placementsTransormer));
      setIsLoading(false);
    }).catch((error) => {
      console.log(`Failed to fetch placements: ${error}`)
    })
  }

  const execute = function () {
    setOperationStatus({
      ...operationStatus,
      status: OperationStatus.IN_EXECUTION
    });

    apiClient.executePlacements(
      currentUser,
      selectedPlacements
    ).then((response) => {
      setOperationStatus({
        ...operationStatus,
        status: OperationStatus.EXECUTED
      });
    }).catch((error) => {
      if (error.response) {
        setOperationStatus({
          status: OperationStatus.FAILED,
          error: error.response.data.message
        });
      }
    });
  }

  return (
    <Container
      sx={{ mt: 4 }}
      maxWidth={false}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <CardLabel text={"Timeframe"} sx={{ mb: 1, mt: 2 }} />
          <Paper elevation={0} className="dashboard-card" sx={{ p: 2 }}>
            <TimeframeInput
              showRefresh={true}
              isLoading={isLoading}
              startDate={startDate}
              endDate={endDate}
              onRefresh={refreshPlacements}
              onStartDateChange={(date) => setStartDate(date.toDate())}
              onEndDateChange={(date) => setEndDate(date.toDate())}
            />
          </Paper>

          <Box
            sx={{
              height: 800,
              width: "100%",
              marginTop: 2
            }}
          >
            <DataGrid
              rows={placements}
              columns={placementColumns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={setSelectedPlacements}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <OperationExecutionStatus
            status={operationStatus.status}
            error={operationStatus.error}
            sx={{ p: 0 }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginBottom: 2,
            textAlign: "right"
          }}
        >
          <ExecuteButton execute={execute} operationStatus={operationStatus.status} />
        </Grid>
      </Grid>
    </Container>
  )
}