import { useState, useEffect } from "react";
import { Pagination, Box, Stack } from "@mui/material";

import { useApiClient } from "../api/client";
import { useAuth } from "../api/auth.context";

import OperationListItem from "./OperationListItem";
import ListItemSkeleton from "../components/ListItemSkeleton";


/**
 * OperationList
 * 
 * Given a status prop (pending or executed) displays
 * a list of paginated operations from the API.
 */
export default function OperationList (props) {
  const itemsPerPage = parseInt(process.env.REACT_APP_DASHBOARD_OPERATIONS_PER_PAGE);

  const apiClient = useApiClient();
  const { currentUser } = useAuth();

  const [operationPages, setOperationPages] = useState([{
    operations: [],
    loaded: false
  }])

  const [pagesCount, setPagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState({
    ...operationPages[page - 1]
  });

  /**
   * Initialization hook: when this component is first
   * rendered fetch the data for the first page and compute
   * how many pages are there
   */
  useEffect(() => {
    apiClient.operations(
      currentUser,
      props.status,
      itemsPerPage,
      0
    ).then((data) => {
      const pagesCount = Math.ceil(data.count / itemsPerPage);
      const pages = Array(pagesCount).fill().map((_, index) => {
        return {
          operations: index === 0 ? data.operations : [],
          loaded: index === 0
        }
      })

      setOperationPages(pages);
      setPagesCount(pagesCount);
    })
  }, [])

  /**
   * Page hook: load page data from the API as needed
   * and update the operationPages state
   */
  useEffect(() => {
    // Skip the first page, whose loading is done
    // in the initialization hook
    if (page === 1) return;

    if (!operationPages[page - 1].loaded) {
      apiClient.operations(
        currentUser,
        props.status,
        itemsPerPage,
        itemsPerPage * (page - 1) // items offset
      ).then((data) => {
        const updatedPages = operationPages.map((chunk, index) => {
          if (index === (page - 1)) {
            return {
              operations: data.operations,
              loaded: true
            }
          }

          return {
            ...chunk
          }
        })

        setOperationPages(updatedPages);
      })
    }
  }, [page])

  /**
   * Page loaded hook: when the operationPages state
   * is updated it updates the current page making
   * sure the latest data is displayed
   */
  useEffect(() => {
    setCurrentPage({
      ...operationPages[page - 1]
    })
  }, [operationPages])

  return (
    <Box>
      {!currentPage.loaded && (
        <Box
          sx={{
            textAlign: "center",
            marginLeft: 2,
            marginRight: 2,
            marginBottom: 2
          }}
        >
          {Array(itemsPerPage).fill().map((_, index) => {
            return (
              <ListItemSkeleton
                key={index}
                titleWidth={"50%"}
                subtitleWidth={"25%"}
                sx={{ marginBottom: 1 }}
              />
            )
          })}
        </Box>
      )}

      {currentPage.loaded && currentPage.operations.map((operation) => {
        return (
          <OperationListItem
            key={operation.id}
            operation={operation}
          />
        )
      })}

      <Stack
        sx={{
          padding: 2,
          paddingTop: 0,
          textAlign: "center"
        }}
      >
        <Pagination
          count={pagesCount}
          page={page}
          onChange={(event, page) => {
            setPage(page);
            setCurrentPage(operationPages[page - 1]);
          }}
          color="primary"
          sx={{
            margin: "auto"
          }}
        />
      </Stack>
    </Box>
  )
}
