import { Typography, Box, Stack } from "@mui/material";

import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/CheckCircle";


export default function MetricListItem(props) {
  return (
    <Box>
      <Box
        sx={{
          padding: 2,
          paddingTop: 0
        }}
      >
        <Stack direction="row" spacing={2}>
          <Box
            sx={{
              display: "flex"
            }}
          >
            {props.valid && (
              <CheckIcon
                sx={{
                  margin: "auto",
                  color: "success.main"
                }}
              />
            )}

            {!props.valid && (
              <ErrorIcon
                sx={{
                  margin: "auto",
                  color: "error.main"
                }}
              />
            )}
          </Box>
          <Box>
            <Typography variant="body1" component="div">
              {props.metric}
            </Typography>
            <Typography variant="caption" component="div">
              {props.totalObservations} total observations ({props.averageObservations} on average)
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
