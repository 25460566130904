import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useSearchParams } from "react-router-dom";
import { useApiClient } from "../../api/client";
import { useAuth } from "../../api/auth.context";
import { Container, Grid } from "@mui/material";
import { selectCustomer } from "../../utils/customer";

import ExecuteButton from "./ExecuteButton";
import CardLabel from "../../components/CardLabel";
import OperationPreviewHeader from "./OperationPreviewHeader";
import OperationExecutionStatus from "./OperationExecutionStatus";

import {
  keywordsPerformanceBroadMatchColumns,
  keywordsPerformanceExactMatchColumns
} from "../../data/tables/operations";

import { OperationStatus } from "../../data/enums/operations";
import { MatchType, matchTypeLabel } from "../../data/enums/generic";

import operationTransformers from "../../utils/transformers/operations";


export default function KeywordsPerformaceOperationPreview() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const apiClient = useApiClient();
  const { currentUser, updateUser } = useAuth();

  const operationId = location.pathname.split("/").pop();
  const [operation, setOperation] = useState({
    generated: null,
    isSkag: false,
    id: null
  });

  const [operationStatus, setOperationStatus] = useState({
    status: OperationStatus.PENDING,
    error: ""
  });

  const [exactMatchKeywords, setExactMatchKeywords] = useState([]);
  const [broadMatchKeywords, setBroadMatchKeywords] = useState([]);

  const [selectedExactMatchKeywords, setSelectedExactMatchKeywords] = useState([]);
  const [selectedBroadMatchKeywords, setSelectedBroadMatchKeywords] = useState([]);

  useEffect(() => {
    // Make sure to select the correct customer for the request
    selectCustomer(currentUser, searchParams, updateUser);
  }, [])

  useEffect(() => {
    apiClient.operation(currentUser, operationId).then((operation) => {
      const items = operation.items.map(operationTransformers.keywordsPerformance);

      const labelFields = (item) => {
        return {
          ...item,
          matchType: matchTypeLabel(item.matchType)
        }
      }

      const exactMatchKeywords = items.filter((row) => row.matchType === MatchType.EXACT).map(labelFields);
      const broadMatchKeywords = items.filter((row) => {
        return (row.matchType === MatchType.BROAD) || (row.matchType === MatchType.PHRASE)
      }).map(labelFields);

      setOperation({
        generated: operation.generated,
        isSkag: operation.isSkag,
        id: operation.id
      });

      setExactMatchKeywords(exactMatchKeywords);
      setBroadMatchKeywords(broadMatchKeywords);
    })
  }, [])

  const execute = function () {
    setOperationStatus({
      ...operationStatus,
      status: OperationStatus.IN_EXECUTION
    });

    const payload = {
      // Merge exact and broad keywords
      targets: selectedExactMatchKeywords.concat(selectedBroadMatchKeywords).map((id) => {
        const ids = id.split("/");
  
        return {
          "keywordId": parseInt(ids[1]),
          "adGroupId": parseInt(ids[0])
        }
      })
    };

    apiClient.executeOperation(currentUser, operationId, payload).then(() => {
      setOperationStatus({
        ...operationStatus,
        status: OperationStatus.EXECUTED
      });
    }).catch((error) => {
      if (error.response) {
        setOperationStatus({
          status: OperationStatus.FAILED,
          error: error.response.data.message
        });
      }
    });
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        marginTop: 4
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <OperationPreviewHeader
            operation={operation}
            title={"Keywords Performance"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 4
          }}
        >
          <CardLabel text={"Broad Match Keywords"} sx={{ marginBottom: 1 }} />
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rowHeight={75}
              headerHeight={64}
              rows={broadMatchKeywords}
              columns={keywordsPerformanceBroadMatchColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(model) => {
                setSelectedBroadMatchKeywords(model);
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 4
          }}
        >
          <CardLabel text={"Exact Match Keywords"} sx={{ marginBottom: 1 }} />
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rowHeight={75}
              headerHeight={64}
              rows={exactMatchKeywords}
              columns={keywordsPerformanceExactMatchColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(model) => {
                setSelectedExactMatchKeywords(model);
              }}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <OperationExecutionStatus
            status={operationStatus.status}
            error={operationStatus.error}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
            marginBottom: 2,
            textAlign: "right"
          }}
        >
          <ExecuteButton execute={execute} operationStatus={operationStatus.status} />
        </Grid>
      </Grid>
    </Container>
  );
}
