import { useEffect, useState } from "react";
import { useApiClient } from "../../../api/client";
import { Dialog,  DialogTitle, List, ListItem, ListItemText } from "@mui/material";


export default function LoadTemplateDialog(props) {
  const apiClient = useApiClient();

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    apiClient.rulesTemplates().then((templates) => {
      setTemplates(templates);
    })
  }, [])

  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <DialogTitle>Load Template</DialogTitle>
      <List sx={{ pt: 0 }}>
        {templates.map((template) => (
          <ListItem
            button
            onClick={() => props.handleSelected(template)}
            key={template.name}
            sx={{ pl: 3 }}
          >
            <ListItemText primary={template.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
