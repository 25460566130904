import { useEffect, useState } from "react";

import { Container, Grid, Paper, Stack } from "@mui/material";

import CardLabel from "../components/CardLabel";

import OperationList from "../components/OperationList";
import IconListItemSkeleton from "../components/IconListItemSkeleton";
import OperationGenerationStatus from "../components/OperationGenerationStatus";

import { operationTypeLabel, OperationStatus } from "../data/enums/operations";

import { useApiClient } from "../api/client";
import { useAuth } from "../api/auth.context";


export default function Dashboard() {
  const apiClient = useApiClient();
  const { currentUser } = useAuth();

  const [operationGenerationStatuses, setOperationGenerationStatuses] = useState([]);

  useEffect(() => {
    apiClient.operationGenerationStatus(currentUser).then((statuses) => {
      setOperationGenerationStatuses(statuses);
    })
  }, []);

  return (
    <Container sx={{ mt: 4 }} maxWidth={false}>
      <Grid
        container
        spacing={4}
      >
        <Grid item xs={8}>
          <CardLabel text={"Pending Operations"} sx={{ marginBottom: 1 }} />
          <Paper elevation={0} className="dashboard-card" sx={{ paddingTop: 2, marginBottom: 3 }}>
            <OperationList status={OperationStatus.PENDING} />
          </Paper>

          <CardLabel text={"Executed Operations"} sx={{ marginBottom: 1 }} />
          <Paper elevation={0} className="dashboard-card" sx={{ paddingTop: 2 }}>
            <OperationList status={OperationStatus.EXECUTED} />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <CardLabel text={"Generation Status"} sx={{ marginBottom: 1 }} />
          <Paper
            elevation={0}
            className="dashboard-card"
          >
            {operationGenerationStatuses.length === 0 && (
              <Stack spacing={2} sx={{ margin: 2 }}>
                <IconListItemSkeleton />
                <IconListItemSkeleton />
                <IconListItemSkeleton />
                <IconListItemSkeleton />
              </Stack>
            )}
            {operationGenerationStatuses.map((status, index) => {
              return (
                <OperationGenerationStatus
                  key={index}
                  name={operationTypeLabel(status.type)}
                  generated={status.generated}
                />
              )
            })}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
