const budgetGroupsPacingMetricsSkeleton = {
    timeframeEndDate: null,
    generatedOn: null,
    cumulativeCost: null,
    forecastTotalSpend: null,
    percentageForecastTotalSpend: null,
    budgetStatus: "unknown"
}

export {
    budgetGroupsPacingMetricsSkeleton
}