import { intFormatter } from "../../utils/format";
import { currencyFormatter } from "../../utils/format";

const budgetGroupsColumns = [
  { field: "id", headerName: "ID", width: 250, type: "string" },
  { field: "name", headerName: "Budget Name", width: 200, type: "string" },
  { field: "amount", headerName: "Amount", width: 200, valueFormatter: currencyFormatter, type: "number" },
  { field: "startDate", headerName: "Start Date", width: 200, type: "date" },
  { field: "endDate", headerName: "End Date", width: 200, type: "date" },
  { field: "campaignsCount", headerName: "Campaigns No.", width: 150, valueFormatter: intFormatter, type: "number" },
];

const searchTermsListColumns = [
  { field: "id", headerName: "Name", width: 500, type: "string", flex: 1 }
];

export {
  budgetGroupsColumns,
  searchTermsListColumns
};