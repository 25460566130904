const keywordsExtractionVisibilityModel = {
  id: false
}

const keywordsExtractionColumns = [
  { field: "id", headerName: "ID", type: "number", width: 250 },
  { field: "keyword", headerName: "Keyword", type: "string", width: 250 },
  { field: "score", headerName: "Score", type: "number", width: 150, description: "Keyword score. Lower is better" }
]

export {
  keywordsExtractionColumns,
  keywordsExtractionVisibilityModel
}