const moment = require("moment");

function formatTimestamp (timestamp) {
  return moment.unix(timestamp).format(process.env.REACT_APP_DATETIME_FORMAT);
}

function currentDateTimeString () {
  return moment().format(process.env.REACT_APP_DATETIME_FORMAT);
}

export {
  formatTimestamp,
  currentDateTimeString
};
