import { Box, Typography, Stack } from "@mui/material";

import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/CheckCircle";

import ReactTimeAgo from "react-time-ago";

import { formatTimestamp } from "../utils/time";

const moment = require("moment");


export default function OperationGenerationStatus(props) {
  /**
   * Returns whether the operation generation failed.
   * 
   * Operations which haven't been generated for
   * REACT_APP_OPERATION_GENERATION_MAX_DAYS days
   * or longer are considered to be "failures"
   */
  const maximumDays = parseInt(process.env.REACT_APP_OPERATION_GENERATION_MAX_DAYS);
  const hasGenerationError = props.generated == null || moment(new Date()).diff(
    moment.unix(props.generated),
    "days"
  ) >= maximumDays;

  return (
    <Box>
      <Box
        sx={{
          padding: 2
        }}
      >
        <Stack direction="row" spacing={2}>
          <Box
            sx={{
              display: "flex"
            }}
          >
            {!hasGenerationError && (
              <CheckIcon
                sx={{
                  margin: "auto",
                  color: "success.main"
                }}
              />
            )}

            {hasGenerationError && (
              <ErrorIcon
                sx={{
                  margin: "auto",
                  color: "error.main"
                }}
              />
            )}
          </Box>
          <Box>
            <Typography variant="body1" component="div">
              {props.name}
            </Typography>
            {props.generated && (
              <Box>
                <Typography variant="caption" component="div">
                  Last generated <ReactTimeAgo date={props.generated * 1000} />
                </Typography>
                <Typography variant="caption" component="div">
                  {formatTimestamp(props.generated)}
                </Typography>
              </Box>
            )}

            {props.generated == null && (
              <Box>
                <Typography variant="caption" component="div">
                  Never generated
                </Typography>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
