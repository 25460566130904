const AdvertisingChannelType = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  SEARCH: 2,
  DISPLAY: 3,
  SHOPPING: 4,
  HOTEL: 5,
  VIDEO: 6,
  MULTI_CHANNEL: 7,
  LOCAL: 8,
  SMART: 9,
  PERFORMANCE_MAX: 10,
  LOCAL_SERVICES: 11,
  DISCOVERY: 12
}

export {
  AdvertisingChannelType
}