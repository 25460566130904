import {
  GridToolbarContainer,
  GridToolbarExport
} from "@mui/x-data-grid";


export default function DataGridExportToolbar(props) {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={props.csvOptions ?? {}} />
    </GridToolbarContainer>
  );
}