/**
 * Verifies that a search term can be created and returns
 * a string with the issues, if any. This function checks
 * for the following constraints:
 * 
 * - length: does the search term have less than 80 chars?
 * - words: does the search term have less than 80 words?
 */
function validateSearchTerm (searchTerm) {
    const issues = [];

    if (searchTerm.length > 80) issues.push("too long");
    if (searchTerm.split(" ").length > 10) issues.push("too many words");

    return issues.join(", ");
}

export { validateSearchTerm };
 