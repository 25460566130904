import CrescendaSelect from "../CrescendaSelect";

import { Typography, Box } from "@mui/material";
import { formatSnakeCase } from "../../utils/format";


export default function ActionsCard (props) {
  const availableActions = props.actions.map((ac) => ac.id).map((action) => {
    return {
      label: formatSnakeCase({ value: action }),
      value: action
    }
  });

  const currentAction = props.actions
    .filter((ac) => ac.id === props.selectedAction)
    .pop();

  const currentActionOption = currentAction ? {
    label: formatSnakeCase({ value: currentAction.id }),
    value: currentAction.id
  } : { label: "", value: "" };

  return (
    <Box sx={{ mt: 2 }}>
      <CrescendaSelect
        fullWidth
        label={"Action"}
        options={availableActions}
        value={currentActionOption.value}
        onChange={(action) => {
          props.setSelectedAction(action.value)
        }}
      />

      {currentAction && (
        <Typography
          variant="body1"
          component="div"
          sx={{
            marginTop: 1
          }}
        >
          {currentAction.description}
        </Typography>
      )}
    </Box>
  )
}