const routeNames = [
    {
        path: "/",
        name: "Dashboard",
        maxWidth: false,
        exact: false
    },
    {
        path: "/budget-pacing",
        name: "Budget Pacing Report",
        maxWidth: false,
        exact: false
    },
    {
        path: "/help",
        name: "Help",
        maxWidth: false,
        exact: false
    },
    {
        path: "/placements",
        name: "Placements Manager",
        maxWidth: false,
        exact: false
    },
    {
        path: "/explore",
        name: "Explore",
        maxWidth: false,
        exact: false
    },
    {
        path: "/operations/preview/search-terms/:operationId",
        name: "Operation Preview",
        maxWidth: false,
        exact: false
    },
    {
        path: "/operations/preview/search-terms/:operationId/summary",
        name: "Search Terms Review Summary",
        maxWidth: false,
        exact: false
    },
    {
        path: "/operations/preview/ad-groups-optimization/:operationId",
        name: "Operation Preview",
        maxWidth: false,
        exact: false
    },
    {
        path: "/operations/preview/keywords-performance/:operationId",
        name: "Operation Preview",
        maxWidth: false,
        exact: false
    },
    {
        path: "/operations/preview/cpc-update/:operationId",
        name: "Operation Preview",
        maxWidth: false,
        exact: false
    },
    {
        path: "/clustering",
        name: "Clustering",
        maxWidth: false,
        exact: false
    },
    {
        path: "/clustering/:id",
        name: "Clustering",
        maxWidth: false,
        exact: false
    },
    {
        path: "/clustering/create",
        name: "Create Clustering Job",
        maxWidth: false,
        exact: false
    },
    {
        path: "/keywords-extraction",
        name: "Keywords Extraction",
        maxWidth: false,
        exact: false
    },
    {
        path: "/campaign-budget-editor",
        name: "Campaign Budget Editor",
        maxWidth: false,
        exact: false
    },
    {
        path: "/settings",
        name: "Settings",
        maxWidth: false,
        exact: false
    }
]

export { routeNames };
