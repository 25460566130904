import { Skeleton, Box } from "@mui/material";


export default function ListItemSkeleton (props) {
  const titleWidth = props.titleWidth || 128;
  const subtitleWidth = props.subtitleWidth || "100%";

  const sx = props.sx || {};

  return (
    <Box sx={sx}>
      <Skeleton variant="text" height={32} width={titleWidth} />
      <Skeleton variant="text" width={subtitleWidth} />
    </Box>
  )
}