import axios from "axios";

import { amount2micros } from "../utils/ads";
import { getLocalUser } from "./auth.context";

import columnTransformer from "../utils/transformers/rules";
import {
  budgetGroupsTransformer,
  budgetGroupsPacingMetricsTransformer
} from "../utils/transformers/budgetGroups";

const moment = require("moment");


export default class AdsManagerApiClient {
  constructor () {
    const user = getLocalUser();

    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_ROOT,
      headers: { Authorization: `Bearer ${user.token}` }
    });
  }

  dateToUTCTimestamp (date) {
    const utcOffset = date.getTimezoneOffset() * 60;
    return moment(date).unix() - utcOffset;
  }

  /**
   * Formats a bool as an integer:
   * 
   * false = 0
   * true = 1
   * 
   * @param {bool} value 
   */
  formatBool (value) {
    return value ? 1 : 0
  }

  /**
   * Formats a date to the ISO8601 format
   *
   * @param {Date} value
   */
  formatISODate (value) {
    return moment(value).format("YYYY-MM-DD")
  }

  /**
   * Returns a query dictionary with the
   * user's current customer
   */
  customerQueryParam (user) {
    return {
      params: { customer: user.customer }
    }
  }

  login (email, password, recaptchaToken) {
    const payload = {
      email: email,
      password: password,
      recaptchaToken: recaptchaToken
    };

    return this.client.post("/user/login", payload).then((response) => {
      return {
        token: response.data.token,
        customer: parseInt(response.data.customer)
      };
    });
  }

  healthCheck () {
    return this.client.get("/health-check");
  }

  customers () {
    return this.client.get("/user/customers").then((response) => {
      return response.data.customers;
    });
  }

  /**
   * GET the members of an account-level
   * search terms list
   * 
   * @param {SearchTermsList} listType 
   */
  searchTermsList (user, listType) {
    return this.client.get(
      `/user/search-terms-lists/${listType}`,
      this.customerQueryParam(user)
    ).then((response) => {
      return response.data.searchTerms;
    });
  }

  /**
   * Entity lists routes
   */
  campaigns (user, includeExperiments) {
    const config = {
      params: {
        customer: user.customer,
        includeExperiments: this.formatBool(includeExperiments)
      }
    }

    return this.client.get("/campaigns", config).then((response) => {
      return response.data.campaigns;
    });
  }

  sharedSets (user) {
    return this.client.get("/shared-sets", this.customerQueryParam(user)).then((response) => {
      return response.data.sharedSets;
    });
  }

  /**
   * Operations routes
   */
  operationGenerationStatus (user) {
    return this.client.get("/operations/status", this.customerQueryParam(user)).then((response) => {
      return response.data.operations;
    });
  }

  placements (user, startDate, endDate) {
    const config = {
      params: {
        customer: user.customer,
        startTimestamp: this.dateToUTCTimestamp(startDate),
        endTimestamp: this.dateToUTCTimestamp(endDate)
      }
    }

    return this.client.get("/placements/", config).then((response) => {
      return response.data.placements;
    });
  }

  executePlacements (user, placements) {
    const payload = { targets: placements };

    return this.client.post("/placements/execute", payload, this.customerQueryParam(user));
  }

  operations (user, status, limit, offset) {
    const config = {
      params: {
        customer: user.customer,
        status: status,
        limit: limit,
        offset: offset
      }
    }

    return this.client.get("/operations/", config).then((response) => {
      return response.data;
    });
  }

  operation (user, operationId) {
    return this.client.get(`/operations/${operationId}`, this.customerQueryParam(user)).then((response) => {
      return response.data;
    });
  }

  executeOperation (user, operationId, payload) {
    return this.client.post(`/operations/${operationId}/execute`, payload, this.customerQueryParam(user)).then((response) => {
      return response.data;
    });
  }

  /**
   * Campaign Budget Editor
   */
  campaignBudgets (user, startDate, endDate, advertisingChannelTypes, budgetGroupId) {
    const config = {
      params: {
        customer: user.customer,
        startTimestamp: this.dateToUTCTimestamp(startDate),
        endTimestamp: this.dateToUTCTimestamp(endDate),
        advertisingChannelTypes: advertisingChannelTypes.join(","),
        budgetGroupId: budgetGroupId
      }
    }

    return this.client.get("/budget-editor", config);
  }

  updateCampaignBudgets (user, budgets) {
    const payload = {
      targets: budgets.map((budget) => {
        return {
          budgetId: budget.budgetId,
          value: amount2micros(budget.proposedBudget)
        }
      })
    }

    return this.client.post("/budget-editor/execute", payload, this.customerQueryParam(user));
  }

  /**
   * Reports
   */
  budgetPacingReport (user, startDate, endDate, timeframeEndDate, budget, advertisingChannelTypes, budgetGroupId) {
    const payload = {
      budgetStart: this.formatISODate(startDate),
      budgetEnd: this.formatISODate(endDate),
      timeframeEnd: this.formatISODate(timeframeEndDate),
      budget: parseInt(budget),
      advertisingChannelTypes: advertisingChannelTypes,
      budgetGroupId: budgetGroupId
    }

    const config = {
      responseType: "blob",
      params: {
        customer: user.customer
      }
    }

    return this.client.post("/reports/budget-pacing", payload, config).then((response) => {
      return URL.createObjectURL(response.data);
    });
  }

  /**
   * Rules
   */
  reportDefinitions () {
    return this.client.get("/rules/reports").then((response) => {
      return response.data.reports;
    });
  }

  fetchReportData (user, reportName, timeInterval, resourceFields, segmentsFields, metricsFields, crescendaFields, filters) {
    const payload = {
      fields: {
        resource: resourceFields,
        metrics: metricsFields,
        segments: segmentsFields,
        crescenda: crescendaFields
      },
      filters: filters.map((filter) => {
        // Remove the filter id
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
          logicalOperator: filter.logicalOperator,
          indentationLevel: filter.indentationLevel
        }
      }),
      timeInterval: timeInterval
    }

    return this.client.post(`/rules/reports/${reportName}/fetch`, payload, this.customerQueryParam(user)).then((response) => {
      return {
        name: reportName,
        columns: response.data.columns.map(columnTransformer),
        rows: response.data.rows
      }
    });
  }

  executeRulesOperation (user, reportName, queries, action) {
    const payload = { queries: queries, action: action };

    return this.client.post(`/rules/reports/${reportName}/execute`, payload, this.customerQueryParam(user));
  }

  rulesTemplates () {
    return this.client.get("/rules/reports/templates").then((response) => {
      return response.data.templates;
    });
  }

  saveRulesTemplate (
    templateName,
    reportName,
    timeInterval,
    resourceFields,
    segmentsFields,
    metricsFields,
    crescendaFields,
    filters
  ) {
    const payload = {
      name: templateName,
      report: reportName,
      fields: {
        resource: resourceFields,
        metrics: metricsFields,
        segments: segmentsFields,
        crescenda: crescendaFields
      },
      filters: filters,
      timeInterval: timeInterval
    }

    return this.client.post("/rules/reports/templates", payload);
  }

  /**
   * Settings
   */
  budgetGroups (user) {
    return this.client.get("/budget-groups", this.customerQueryParam(user)).then((response) => {
      return response.data.budgetGroups.map(budgetGroupsTransformer);
    });
  }

  budgetGroupsPacingMetrics (user) {
    return this.client.get("/budget-groups/pacing-metrics", this.customerQueryParam(user)).then((response) => {
      return response.data.budgetGroups.map(budgetGroupsPacingMetricsTransformer);
    });
  }

  saveBudgetGroup (user, name, amount, campaignIds, startDate, endDate) {
    const payload = {
      name: name,
      amount: amount2micros(amount),
      campaignIds: campaignIds,
      startDate: this.formatISODate(startDate),
      endDate: this.formatISODate(endDate)
    }

    return this.client.post("/budget-groups", payload, this.customerQueryParam(user));
  }

  updateBudgetGroup (user, budgetGroupId, name, amount, campaignIds, startDate, endDate) {
    const payload = {
      name: name,
      amount: amount2micros(amount),
      campaignIds: campaignIds,
      startDate: this.formatISODate(startDate),
      endDate: this.formatISODate(endDate)
    }

    return this.client.put(
      `/budget-groups/${budgetGroupId}`,
      payload,
      this.customerQueryParam(user)
    );
  }

  deleteBudgetGroup (user, budgetGroupId) {
    return this.client.delete(`/budget-groups/${budgetGroupId}`, this.customerQueryParam(user));
  }

  /**
   * Clustering
   */
  clusteringJobs (user) {
    return this.client.get("/clustering/", this.customerQueryParam(user));
  }

  clusteringJob (user, jobId) {
    return this.client.get(`/clustering/${jobId}`, this.customerQueryParam(user));
  }

  clusteringJobProgress (user, jobId) {
    return this.client.get(`/clustering/${jobId}/progress`, this.customerQueryParam(user));
  }

  submitClusteringJob (user, jobConfiguration) {
    return this.client.post("/clustering/", jobConfiguration, this.customerQueryParam(user));
  }

  clusteringJobReport (user, jobId) {
    const config = {
      ...this.customerQueryParam(user),
      responseType: "blob"
    }

    return this.client.get(`/clustering/${jobId}/report`, config).then((response) => {
      return URL.createObjectURL(response.data);
    });
  }

  /**
   * Keywords Extraction
   */
  extractFromText (user, input) {
    return this.client.post(
      "/keywords-extraction/text",
      { input: input },
      this.customerQueryParam(user)
    );
  }

  extractFromFile (user, fileData, mimeType) {
    const requestParams = {
      ...this.customerQueryParam(user),
      headers: { "Content-Type": "multipart/form-data" }
    }

    const formData = new FormData();
    formData.append("input", new Blob([fileData], { type: mimeType }));

    return this.client.post(
      "/keywords-extraction/file",
      formData,
      requestParams
    );
  }
}

export const useApiClient = () => new AdsManagerApiClient();
