import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button
} from "@mui/material";
import { getFormError, getFormErrorSkeleton } from "../../../data/form";


export default function SaveTemplateDialog(props) {
  const [templateName, setTemplateName] = useState("");
  const [error, setError] = useState(getFormErrorSkeleton())

  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <DialogTitle>Save Template</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Template name"
          variant="outlined"
          value={templateName}
          sx={{ minWidth: 256 }}
          error={error.error}
          helperText={error.message}
          onChange={(event) => {
            setTemplateName(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={props.handleClose}>Cancel</Button>
        <Button onClick={() => {
          if (templateName.length === 0) {
            setError(getFormError("Invalid name"));
          } else {
            props.handleSave(templateName);

            setError(getFormErrorSkeleton());
            setTemplateName("");
          }
        }}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
