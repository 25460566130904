import { Box, Snackbar, Alert } from "@mui/material";


function CrescendaSnackbar (props) {
  return (
    <Box>
      <Snackbar
        open={props.open}
        autoHideDuration={3000}
        onClose={props.onClose}
      >
        <Alert onClose={props.onClose} severity={props.severity} sx={{ width: "100%" }}>
          {props.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

function defaultSnackbarState () {
  return {
    open: false,
    message: "",
    severity: "success"
  }
}

function successSnackbarState (message) {
  return {
    open: true,
    message: message,
    severity: "success"
  }
}

function errorSnackbarState (message) {
  return {
    open: true,
    message: message,
    severity: "error"
  }
}

export {
  CrescendaSnackbar,
  errorSnackbarState,
  defaultSnackbarState,
  successSnackbarState
}