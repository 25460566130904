import { micros2amount, roundAmount } from "../ads";


const placementsTransormer = (item) => {
  return {
    id: item.target_url,
    type: item.type,
    clicks: item.metrics.clicks,
    conversions: item.metrics.conversions,
    impressions: item.metrics.impressions,
    cost: micros2amount(item.metrics.cost),
    costConversion: roundAmount(item.metrics.cost_per_conversion),
    cpc: roundAmount(item.metrics.cpc),
    cpm: roundAmount(item.metrics.cpm),
    ctr: roundAmount(item.metrics.ctr)
  }
}

export default placementsTransormer;