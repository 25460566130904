import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import { useApiClient } from "../../api/client";
import { useAuth } from "../../api/auth.context";
import { Box, Container, Grid } from "@mui/material";
import { SearchTermType } from "../../data/enums/operations";
import { searchTermsReviewSummaryColumns } from "../../data/tables/operations";

import ExecuteButton from "./ExecuteButton";
import OperationExecutionStatus from "./OperationExecutionStatus";

import { OperationStatus } from "../../data/enums/operations";

export default function SearchTermsReviewOperationSummary () {
  const location = useLocation()
  const apiClient = useApiClient()

  const { currentUser } = useAuth()

  // Extract the operation id from the URL
  const pathComponents = location.pathname.split("/")
  const operationId = pathComponents[pathComponents.length - 2];

  const { negativeTerms, positiveTerms } = location.state

  const defaultSharedSet = {
    id: 0,
    name: "None"
  }

  const [operationStatus, setOperationStatus] = useState({
    status: OperationStatus.PENDING,
    error: ""
  });

  const getOperationTerms = () => {
    return negativeTerms.map((term) => {
      return {
        ...term,
        displayTermType: term.type,
        negativeKeywordsList: defaultSharedSet.name
      }
    }).concat(positiveTerms.map((term) => {
      return {
        ...term,
        displayTermType: SearchTermType.POSITIVE,
        negativeKeywordsList: defaultSharedSet.name
      }
    }))
  }

  const [sharedSets, setSharedSets] = useState([])
  const [operationTerms, setOperationTerms] = useState(getOperationTerms())
  const [selectedTerms, setSelectedTerms] = useState([])

  const rowHeight = 75;
  const tableHeight = 600;
  const tableColumns = [
    ...searchTermsReviewSummaryColumns,
    {
      field: "negativeKeywordsList",
      headerName: "Negative Keyword List",
      width: 200,
      type: "singleSelect",
      editable: true,
      valueOptions: sharedSets.map((set) => set.name),
      description: "The negative keywords list this term should be added to. This only applies to negative terms"
    }
  ]

  useEffect(() => {
    apiClient.sharedSets(currentUser).then((remoteSharedSets) => {
      setSharedSets([defaultSharedSet].concat(remoteSharedSets))
    })
  }, [])

  useEffect(() => {
    setOperationTerms(getOperationTerms())
  }, [negativeTerms, positiveTerms])

  const baseSearchTermPayload = (term) => {
    return {
      id: term.id,
      finalSearchTerm: term.searchTerm
    }
  }

  const execute = () => {
    setOperationStatus({
      ...operationStatus,
      status: OperationStatus.IN_EXECUTION
    })

    // Apply the operation only to the selected terms
    const selectedTermsIds = new Set(selectedTerms);
    const targetTerms = operationTerms.filter((term) => selectedTermsIds.has(term.id))

    const positiveRecords = targetTerms.filter(
      (term) => term.displayTermType === SearchTermType.POSITIVE
    ).map(baseSearchTermPayload)

    const negativeRecords = targetTerms.filter(
      (term) => term.displayTermType === SearchTermType.NEGATIVE
    ).map(term => {
      return {
        ...baseSearchTermPayload(term),
        // FIXME: Make lowercase, but for now the backend accepts it
        level: term.level
      }
    })

    // Select the ignore and ask the customer keywords lists
    const ignoredRecords = targetTerms.filter(
      (term) => term.displayTermType === SearchTermType.IGNORE
    ).map(baseSearchTermPayload)

    const askCustomerRecords = targetTerms.filter(
      (term) => term.displayTermType === SearchTermType.ASK_CUSTOMER
    ).map(baseSearchTermPayload)

    const payload = {
      targets: {
        negative: negativeRecords,
        positive: positiveRecords,
        ignore: ignoredRecords,
        askCustomer: askCustomerRecords
      }
    }

    apiClient.executeOperation(currentUser, operationId, payload).then(() => {
      setOperationStatus({
        ...operationStatus,
        status: OperationStatus.EXECUTED
      });
    }).catch((error) => {
      if (error.response) {
        setOperationStatus({
          status: OperationStatus.FAILED,
          error: error.response.data.message
        });
      }
    });
  }

  return (
    <Container
      maxWidth={false}
      sx={{ mt: 4 }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ height: tableHeight, width: "100%" }}>
            <DataGrid
              headerHeight={72}
              experimentalFeatures={{ newEditingApi: true }}
              rows={operationTerms}
              columns={tableColumns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              rowHeight={rowHeight}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(model) => setSelectedTerms(model)}
              processRowUpdate={(row) => {
                setOperationTerms(operationTerms.map((term) => {
                  if (row.id === term.id) {
                    return {
                      ...term,
                      negativeKeywordsList: row.negativeKeywordsList
                    }
                  }

                  return term
                }))

                return row
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <OperationExecutionStatus
            status={operationStatus.status}
            error={operationStatus.error}
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: 2, mb: 2, textAlign: "right" }}>
          <ExecuteButton execute={execute} operationStatus={operationStatus.status} />
        </Grid>
      </Grid>
    </Container>
  )
}