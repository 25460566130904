import { Stack, Typography } from "@mui/material";

import CheckIcon from "@mui/icons-material/CheckCircle";


export default function SuccessLogRow (props) {
  return (
    <Stack direction={"row"}>
      <CheckIcon
        sx={{
          margin: "auto",
          color: "success.main",
          marginRight: 1
        }}
      />
      <Typography
        className="log-text"
        variant="body2"
        component="div"
        gutterBottom
        sx={{ flexGrow: 1, marginTop: "auto", marginBottom: "auto" }}
      >
        {props.result.item}: action executed
      </Typography>
    </Stack>
  )
}