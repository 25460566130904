function roundAmount (amount) {
    const value = amount || 0;
    return parseFloat(value.toFixed(2));
}

function micros2amount (micros) {
    return roundAmount(micros / 1000000.0);
}

function amount2micros (amount) {
    // Make sure the amount has only two decimal places
    const roundedAmount = parseFloat(parseFloat(amount).toFixed(2));
    return parseInt((roundedAmount * 1000000).toFixed());
}

export {
    roundAmount,
    micros2amount,
    amount2micros
};
