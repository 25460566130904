import { Box, Typography, Stack } from "@mui/material";

import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";


/**
 * Dropzone wrapper component.
 * 
 * @param {*} props: props dictionary. This must contain an "accept"
 *                   object, with the following format:
 * 
 *                   https://react-dropzone.js.org/#section-accepting-specific-file-types
 * 
 *                   and a label property
 * @returns 
 */
export default function CrescendaDropzone (props) {
  return (
    <Dropzone
      onDrop={props.onDrop}
      maxFiles={1}
      accept={props.accept}
    >
      {({ getRootProps, getInputProps }) => (
        <Box {...getRootProps()} sx={{ mt: 2 }}>
          <input {...getInputProps()} />

          <Box
            sx={{
              mt: 0,
              display: "flex",
              height: 128,
              borderStyle: "solid",
              borderWidth: 2,
              borderColor: "rgba(0, 0, 0, 0.1)",
              borderStyle: "dashed"
            }}
          >
            <Stack spacing={1} sx={{ margin: "auto", textAlign: "center" }}>
              <CloudUploadIcon
                fontSize="large"
                sx={{ ml: "auto", mr: "auto", color: "rgba(0, 0, 0, 0.84)" }}
              />

              <Typography variant="body2" component="div">
                {props.label}
              </Typography>
            </Stack>
          </Box>
        </Box>
      )}
    </Dropzone>
  );
}