import {
  Box,
  Grid,
  Chip,
  Paper,
  Stack,
  Select,
  Button,
  Tooltip,
  MenuItem,
  TextField,
  Container,
  IconButton,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment
} from "@mui/material";

import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useApiClient } from "../api/client";
import { useAuth } from "../api/auth.context";
import { googleAdsTimeframes, rulesSkeletons, timeframeByValue } from "../data/rules";
import { OperationStatus, defaultOperationStatus } from "../data/enums/operations";
import { formatSnakeCase } from "../utils/format";

import CardLabel from "../components/CardLabel";
import SearchIcon from "@mui/icons-material/Search";
import OperationExecutionStatus from "./operations/OperationExecutionStatus";

import RulesFilters from "../components/rules/RulesFilters";
import ActionsCard from "../components/rules/ActionsCard";
import ExecutionLog from "../components/rules/log/ExecutionLog";
import CrescendaSelect from "../components/CrescendaSelect";
import FieldDescriptionAccordion from "../components/rules/fields/FieldDescriptionAccordion";

import LoadTemplateDialog from "../components/rules/dialogs/LoadTemplateDialog";
import SaveTemplateDialog from "../components/rules/dialogs/SaveTemplateDialog";

import SaveIcon from "@mui/icons-material/Save";
import FileOpenIcon from "@mui/icons-material/FileOpen";

import {
  CrescendaSnackbar,
  errorSnackbarState,
  defaultSnackbarState,
  successSnackbarState
} from "../components/CrescendaSnackbar";


export default function Explore () {
  const apiClient = useApiClient();
  const { currentUser } = useAuth();

  const [reports, setReports] = useState([]);
  const [reportDefinitions, setReportDefinitions] = useState({});

  const [loadTemplateDialogOpen, setLoadTemplateDialogOpen] = useState(false);
  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState(false);

  const [currentTimeframe, setCurrentTimeframe] = useState({ ...googleAdsTimeframes[0] });
  const [currentReport, setCurrentReport] = useState(structuredClone(rulesSkeletons.report));

  const [reportData, setReportData] = useState(structuredClone(rulesSkeletons.reportData));

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");

  const [executionLog, setExecutionLog] = useState(structuredClone(rulesSkeletons.executionLog));

  const [filters, setFilters] = useState([]);
  const [selectedFields, setSelectedFields] = useState(new Set());

  const [fetchStatus, setFetchStatus] = useState({ ...defaultOperationStatus });
  const [executionStatus, setExecutionStatus] = useState({ ...defaultOperationStatus });

  const [snackbarData, setSnackbarData] = useState(defaultSnackbarState());

  const resetReportData = () => {
    setReportData(structuredClone(rulesSkeletons.reportData));
    setExecutionLog(structuredClone(rulesSkeletons.executionLog));

    setFilters([]);
    setSelectedItems([]);
    setSelectedFields(new Set());

    setSelectedAction("");

    setFetchStatus({ ...defaultOperationStatus });
    setExecutionStatus({ ...defaultOperationStatus })
  }

  const handleFieldSelected = (targetField, selected) => {
    const updatedSelectedFields = new Set(selectedFields);

    if (selected) {
      updatedSelectedFields.add(targetField);
    } else {
      updatedSelectedFields.delete(targetField);
    }

    setSelectedFields(updatedSelectedFields);
  }

  useEffect(() => {
    apiClient.reportDefinitions().then((reports) => {
      setReportDefinitions(reports);
      setReports(Object.keys(reports).map((reportName) => {
        return {
          label: formatSnakeCase({ value: reportName }),
          value: reportName
        }
      }));
    })
  }, [])

  const selectedFieldsByType = (prefix) => {
    return [...selectedFields]
      .filter((name) => name.startsWith(prefix))
      .map((name) => name.replaceAll(prefix, ""));
  }

  const selectedFieldsByCategory = () => {
    const metricsFields = selectedFieldsByType("metrics.");
    const segmentsFields = selectedFieldsByType("segments.");
    const crescendaFields = selectedFieldsByType("crescenda.");
    const resourceFields = selectedFieldsByType(`${currentReport.name}.`);

    // The remaining fields refer to sub-resources
    const subresourceFields = [...selectedFields]
      .filter((name) => !name.startsWith("metrics."))
      .filter((name) => !name.startsWith("crescenda."))
      .filter((name) => !name.startsWith("segments."))
      .filter((name) => !name.startsWith(`${currentReport.name}.`));
    
    return {
      resource: resourceFields.concat(subresourceFields),
      metrics: metricsFields,
      segments: segmentsFields,
      crescenda: crescendaFields
    }
  }

  const fetchReport = () => {
    setFetchStatus({
      ...fetchStatus,
      status: OperationStatus.IN_EXECUTION
    });

    const fieldsByCategory = selectedFieldsByCategory();

    apiClient.fetchReportData(
      currentUser,
      currentReport.name,
      currentTimeframe.value,
      fieldsByCategory.resource,
      fieldsByCategory.segments,
      fieldsByCategory.metrics,
      fieldsByCategory.crescenda,
      filters
    ).then((fetchedData) => {
      setReportData(fetchedData);
      setFetchStatus({
        ...fetchStatus,
        status: OperationStatus.EXECUTED
      });
    }).catch((error) => {
      if (error.response) {
        setFetchStatus({
          status: OperationStatus.FAILED,
          error: error.response.data.message
        });
      } else {
        console.log(`Error fetching data: ${error}`);
      }
    });
  }

  const execute = () => {
    setExecutionStatus({
      ...executionStatus,
      status: OperationStatus.IN_EXECUTION
    });

    apiClient.executeRulesOperation(
      currentUser,
      currentReport.name,
      selectedItems,
      selectedAction
    ).then((response) => {
      setExecutionLog(response.data.result);
      setExecutionStatus({
        ...executionStatus,
        status: OperationStatus.EXECUTED
      });
    }).catch((error) => {
      setExecutionStatus({
        error: `${error}`,
        status: OperationStatus.FAILED
      });
    })
  }

  const saveTemplate = (name) => {
    const fieldsByCategory = selectedFieldsByCategory();

    apiClient.saveRulesTemplate(
      name,
      currentReport.name,
      currentTimeframe.value,
      fieldsByCategory.resource,
      fieldsByCategory.segments,
      fieldsByCategory.metrics,
      fieldsByCategory.crescenda,
      filters
    ).then((response) => {
      if (response.status === 200) {
        setSnackbarData(successSnackbarState("Template saved"))
      } else {
        setSnackbarData(errorSnackbarState(`Error saving template: ${response.data.message}`))
      }
    }).catch((error) => {
      let errorString = "";
  
      if (error.response) {
        errorString = error.response.data.message;
      } else {
        errorString = error.toString();
      }

      setSnackbarData(errorSnackbarState(`Error saving template: ${errorString}`))
    })
  }

  const addFilter = (selectedField, selectedOperator, filterValue) => {
    setFilters([
      ...filters,
      {
        id: filters.length,
        field: selectedField,
        operator: selectedOperator,
        value: filterValue,
        // FIXME: Make this an enum?
        logicalOperator: "AND",
        indentationLevel: 0
      }
    ])
  }
  
  const deleteFilter = (targetFilter) => {
    // 1. Remove the deleted filters from the array
    // 2. Decrease the ids of filters placed after the target
    const updatedFilters = filters
      .filter((filter) => filter.id !== targetFilter.id)
      .map((filter) => {
        if (filter.id > targetFilter.id) {
          filter.id -= 1;
        }

        return filter;
      })

    setFilters(updatedFilters);
  }

  const updateFilter = (updatedFilter) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.id === updatedFilter.id) {
        return updatedFilter
      }

      return filter
    })

    setFilters(updatedFilters);
  }

  const moveFilter = (updatedFilter, newIndex) => {
    const otherFilter = filters[newIndex];

    setFilters(filters.map((filter) => {
      if (filter.id === newIndex) {
        return {
          ...updatedFilter,
          id: newIndex
        }
      } else if (filter.id === updatedFilter.id) {
        return {
          ...otherFilter,
          id: updatedFilter.id
        }
      }

      return filter
    }))
  }

  const selectedFieldsChips = [...selectedFields].map((field) => {
    return (
      <Grid item key={field}>
        <Chip
          label={field}
          variant="outlined"
          onDelete={() => {
            // Unselect the field
            handleFieldSelected(field, false);
          }}
        />
      </Grid>
    )
  });

  return (
    <Container sx={{ marginTop: 4 }} maxWidth={false}>
      <SaveTemplateDialog
        open={saveTemplateDialogOpen}
        handleClose={() => {
          setSaveTemplateDialogOpen(false);
        }}
        handleSave={(templateName) => {
          saveTemplate(templateName);
          setSaveTemplateDialogOpen(false);
        }}
      />

      <LoadTemplateDialog
        open={loadTemplateDialogOpen}
        handleClose={() => {
          setLoadTemplateDialogOpen(false)
        }}
        handleSelected={(template) => {
          setReportData(structuredClone(rulesSkeletons.reportData));
          setExecutionLog(structuredClone(rulesSkeletons.executionLog));

          setCurrentReport(
            structuredClone(reportDefinitions[template.report])
          );

          setCurrentTimeframe(timeframeByValue(template.timeInterval));
          setFilters(template.filters);
          setSelectedItems([]);

          const fields = template.fields.resource.map((field) => `${template.report}.${field}`)
            .concat(template.fields.segments.map((field) => `segments.${field}`))
            .concat(template.fields.metrics.map((field) => `metrics.${field}`))
            .concat(template.fields.crescenda.map((field) => `crescenda.${field}`));

          setSelectedFields(new Set([...fields]));
          setSelectedAction("");

          setFetchStatus({ ...defaultOperationStatus });
          setExecutionStatus({ ...defaultOperationStatus });

          setLoadTemplateDialogOpen(false);
          setSnackbarData(successSnackbarState("Template loaded"));
        }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardLabel text={"REPORT"} sx={{ marginBottom: 1 }} />
          <Paper elevation={0} className="dashboard-card" sx={{ padding: 2 }}>
            <Stack direction={"row"} spacing={2}>
              <CrescendaSelect
                fullWidth
                label="Google Ads Report"
                options={reports}
                value={currentReport.name}
                onChange={(selectedReport) => {
                  setCurrentReport(
                    structuredClone(reportDefinitions[selectedReport.value])
                  );

                  resetReportData();
                }}
              />

              <FormControl fullWidth>
                <InputLabel>Time Interval</InputLabel>
                <Select
                  value={currentTimeframe.value}
                  label="Time Interval"
                  onChange={(event) => {
                    const timeframe = googleAdsTimeframes.filter((tf) => {
                      return tf.value === event.target.value;
                    }).pop();

                    setCurrentTimeframe(timeframe);
                  }}
                >
                  {googleAdsTimeframes.map((timeframe) => {
                    return (
                      <MenuItem
                        key={timeframe.value}
                        value={timeframe.value}
                      >
                        {timeframe.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <Stack
                direction="row"
                spacing={1}
                sx={{ marginTop: "auto !important", marginBottom: "auto !important" }}
              >
                <Tooltip title="Save Template">
                  <IconButton
                    onClick={() => {
                      setSaveTemplateDialogOpen(true)
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Load Template">
                  <IconButton
                    onClick={() => {
                      setLoadTemplateDialogOpen(true)
                    }}
                  >
                    <FileOpenIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>

            {(currentReport.name !== "" && currentTimeframe.value !== "") && (
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  variant="body2"
                  component="div"
                  gutterBottom
                >
                  Select items from the {currentReport.name} report for {currentTimeframe.description}
                </Typography>
              </Box>
            )}
            
            <Box sx={{ marginTop: 1 }}>
              <Typography
                variant="subtitle1"
                component="div"
                gutterBottom
              >
                Selected Fields
              </Typography>

              <Grid container spacing={1}>
                {selectedFieldsChips.length > 0 && selectedFieldsChips}
              </Grid>

              {selectedFieldsChips.length === 0 && (
                <Typography
                  variant="body2"
                  component="div"
                  sx={{
                    opacity: 0.54
                  }}
                >
                  No fields selected
                </Typography>
              )}
            </Box>

            <TextField
              fullWidth
              label="Search fields and metrics"
              variant="outlined"
              sx={{ marginTop: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              disabled={currentReport.name === ""}
              value={searchQuery}
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
            />

            {currentReport.name !== "" && (
              <Box sx={{ marginTop: 2 }}>
                <FieldDescriptionAccordion
                  title={"Resource Fields"}
                  handleSelected={handleFieldSelected}
                  selectedFields={selectedFields}
                  fields={currentReport.fields.resource.filter((field) => {
                    return field.fieldName.includes(searchQuery);
                  })}
                />

                <FieldDescriptionAccordion
                  title={"Segment Fields"}
                  handleSelected={handleFieldSelected}
                  selectedFields={selectedFields}
                  fields={currentReport.fields.segments.filter((field) => {
                    return field.fieldName.includes(searchQuery);
                  })}
                />

                <FieldDescriptionAccordion
                  title={"Metrics"}
                  handleSelected={handleFieldSelected}
                  selectedFields={selectedFields}
                  fields={currentReport.fields.metrics.filter((field) => {
                    return field.fieldName.includes(searchQuery);
                  })}
                />

                <FieldDescriptionAccordion
                  title={"CRESCEN.DA"}
                  handleSelected={handleFieldSelected}
                  selectedFields={selectedFields}
                  fields={currentReport.fields.crescenda.filter((field) => {
                    return field.fieldName.includes(searchQuery);
                  })}
                />
              </Box>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <CardLabel text={"FILTERS"} />
          <RulesFilters
            filters={filters}
            report={currentReport}
            addFilter={addFilter}
            updateFilter={updateFilter}
            moveFilter={moveFilter}
            deleteFilter={deleteFilter}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <CardLabel text={"REPORT DATA"} sx={{ marginBottom: 1 }} />
          <div
            style={{
              height: 600,
              width: "100%"
            }}
          >
            <DataGrid
              checkboxSelection
              rowHeight={125}
              rows={reportData.rows}
              columns={reportData.columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick
              getRowId={(row) => {
                return row[currentReport.fields.id.fieldName]
              }}
              onSelectionModelChange={(model) => {
                setSelectedItems(model);
              }}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ paddingTop: "0px !important" }}
        >
          <OperationExecutionStatus
            status={fetchStatus.status}
            error={fetchStatus.error}
            successMessage={"Data updated"}
            failedMessage={"Could not fetch data: "}
            inExecutionMessage={"Running query..."}
          />
        </Grid>

        <Grid item sx={{ textAlign: "right" }} xs={12}>
          <Button
            variant="contained"
            onClick={fetchReport}
          >
            Refresh Data
          </Button>
        </Grid>

        <Grid item xs={12}>
          <CardLabel text={"ACTION"} sx={{ marginBottom: 1 }} />
          <ActionsCard
            actions={currentReport.actions}
            selectedAction={selectedAction}
            setSelectedAction={(action) => {
              setSelectedAction(action)
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ paddingTop: "0px !important" }}
        >
          <OperationExecutionStatus
            status={executionStatus.status}
            error={executionStatus.error}
            successMessage={"Operation executed"}
            failedMessage={"The operation could not be executed: "}
            inExecutionMessage={"Executing operation..."}
          />
        </Grid>

        {executionStatus.status === OperationStatus.EXECUTED && (
          <Grid item xs={12}>
            <ExecutionLog failed={executionLog.failed} executed={executionLog.executed} />
          </Grid>
        )}

        <Grid item sx={{ textAlign: "right" }} xs={12}>
          <Button variant="contained" onClick={execute}>
            Execute
          </Button>
        </Grid>
      </Grid>

      <CrescendaSnackbar
        {...snackbarData}
        onClose={() => {
          setSnackbarData(defaultSnackbarState());
        }}
      />
    </Container>
  )
}