/**
 * This module contains data formatter for MUI's DataGrid
 * and other utilities
 */
import { formatTimestamp } from "./time";


const doubleFormatter = (params) => {
  if (params.value == null) return "";

  return params.value.toFixed(2);
}

const intFormatter = (params) => {
  if (params.value == null) return "";

  return params.value
}

const currencyFormatter = (params) => {
  if (params.value == null) return "";

  return `${params.value.toFixed(2)} $`;
}

const percentageFormatter = (params) => {
  if (params.value == null) return "";

  return `${(params.value * 100).toFixed(2)}%`;
}

const formatSnakeCase = (params) => {
  if (params.value == null) return "";

  return params.value.toLowerCase().split("_").map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }).join(" ")
}

const timestampFormatter = (params) => {
  if (params.value == null) return null;

  return formatTimestamp(params.value)
}

const formatSelectOption = (params) => {
  if (params.value == null) {
    return {
      label: "",
      value: ""
    }
  }

  return {
    label: formatSnakeCase(params),
    value: params.value
  }
}

export {
  doubleFormatter,
  intFormatter,
  currencyFormatter,
  percentageFormatter,
  formatSnakeCase,
  formatSelectOption,
  timestampFormatter
};
