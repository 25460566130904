import { Chip, Stack, Link } from "@mui/material";

/**
 * Renders a cell for the MUI DataGrid intended
 * to display a list of tags. The cell value should
 * be a string containing all tags separated by "; "
 */
const renderTagsCell = (params) => {
  if (params.value.length === 0) return "";

  return (
    <Stack direction="row" spacing={1}>
      {params.value.split("; ").map((org) => {
        return (
          <Chip label={org} key={org} variant="outlined" size="small" />
        )
      })}
    </Stack>
  )
}

function formatPlacement (url) {
  const prefixes = {
    android: "https://play.google.com/store/apps/details?id=",
    ios: "https://itunes.apple.com/us/app/"
  }

  if (url.includes(prefixes.android)) {
    return url.replaceAll(prefixes.android, "Android App: ")
  } else if (url.includes(prefixes.ios)) {
    return url.replaceAll(prefixes.ios, "iOS App: ")
  }

  return url;
}

const renderPlacementUrlCell = (params) => {
  const url = params.value.includes("http") ? params.value : `https://${params.value}`

  return (
    <Link href={url} target="_blank" rel="noreferrer">{formatPlacement(params.value)}</Link>
  )
}

export {
  renderTagsCell,
  renderPlacementUrlCell
}