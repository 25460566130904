import { enumOptionLabels } from "../../data/enums/generic";
import {
  currencyFormatter,
  formatSnakeCase,
  percentageFormatter,
  intFormatter,
  doubleFormatter
} from "../../utils/format";


const budgetEditorVisibilityModel = {
  searchBudgetLostImpressionShare: false,
  contentBudgetLostImpressionShare: false
}

const budgetEditorColumns = [
  { field: "name", headerName: "Campaign Name", width: 150, type: "string" },
  {
    field: "status",
    headerName: "Status",
    width: 75,
    valueFormatter: formatSnakeCase,
    type: "singleSelect",
    description: "The status of the campaign. When a new campaign is added, the status defaults to ENABLED.",
    valueOptions: enumOptionLabels.entityStatus
  },
  {
    field: "daysSinceCreation",
    headerName: "Days Since Creation",
    headerAlign: "left",
    width: 75,
    valueFormatter: intFormatter,
    type: "number",
    description: "The number of days since this campaign was first created"
  },
  {
    field: "impressions",
    headerName: "Impr.",
    headerAlign: "left",
    width: 80,
    valueFormatter: intFormatter,
    type: "number",
    description: "Count of how often your ad has appeared on a search results page or website on the Google Network."
  },
  {
    field: "searchImpressionShare",
    headerName: "Search Impression Share",
    headerAlign: "left",
    width: 100,
    valueFormatter: percentageFormatter,
    type: "number",
    description: "The impressions you've received on the Search Network divided by the estimated number of impressions you were eligible to receive. Note: Search impression share is reported in the range of 0.1 to 1. Any value below 0.1 is reported as 0.0999."
  },
  {
    field: "searchBudgetLostImpressionShare",
    headerName: "Search Impression Share Lost to Budget",
    headerAlign: "left",
    width: 125,
    valueFormatter: percentageFormatter,
    type: "number",
    description: "The estimated percent of times that your ad was eligible to show on the Search Network but didn't because your budget was too low. Note: Search budget lost impression share is reported in the range of 0 to 0.9. Any value above 0.9 is reported as 0.9001."
  },
  {
    field: "contentImpressionShare",
    headerName: "Display Impression Share",
    headerAlign: "left",
    width: 100,
    valueFormatter: percentageFormatter,
    type: "number",
    description: "The impressions you've received on the Display Network divided by the estimated number of impressions you were eligible to receive. Note: Content impression share is reported in the range of 0.1 to 1. Any value below 0.1 is reported as 0.0999."
  },
  {
    field: "contentBudgetLostImpressionShare",
    headerName: "Display Impression Share Lost to Budget",
    headerAlign: "left",
    width: 125,
    valueFormatter: percentageFormatter,
    type: "number",
    description: "The estimated percent of times that your ad was eligible to show on the Display Network but didn't because your budget was too low. Note: Content budget lost impression share is reported in the range of 0 to 0.9. Any value above 0.9 is reported as 0.9001."
  },
  {
    field: "conversions",
    headerName: "Conv.",
    headerAlign: "left",
    width: 70,
    valueFormatter: intFormatter,
    type: "number",
    description: "The number of conversions. This only includes conversion actions which include_in_conversions_metric attribute is set to true. If you use conversion-based bidding, your bid strategies will optimize for these conversions."
  },
  {
    field: "conversionValue",
    headerName: "Conv. Value",
    headerAlign: "left",
    width: 70,
    valueFormatter: doubleFormatter,
    type: "number",
    description: "The value of conversions. This only includes conversion actions which include_in_conversions_metric attribute is set to true. If you use conversion-based bidding, your bid strategies will optimize for these conversions."
  },
  {
    field: "conversionRate",
    headerName: "Conv. Rate",
    headerAlign: "left",
    width: 70,
    valueFormatter: percentageFormatter,
    type: "number",
    description: "The number of conversions divided by the number of clicks. If the campaign has no clicks this will be zero."
  },
  {
    field: "costPerConversion",
    headerName: "Cost Per Conv.",
    headerAlign: "left",
    width: 80,
    valueFormatter: currencyFormatter,
    type: "number",
    description: "The cost of ad interactions divided by conversions. This only includes conversion actions which include_in_conversions_metric attribute is set to true. If you use conversion-based bidding, your bid strategies will optimize for these conversions."
  },
  {
    field: "cost",
    headerName: "Cost",
    headerAlign: "left",
    width: 85,
    valueFormatter: currencyFormatter,
    type: "number",
    description: "The sum of your cost-per-click (CPC) and cost-per-thousand impressions (CPM) costs during this period."
  },
  {
    field: "dailyCost",
    headerName: "Daily Cost",
    headerAlign: "left",
    width: 85,
    valueFormatter: currencyFormatter,
    type: "number",
    description: "The sum of your cost-per-click (CPC) and cost-per-thousand impressions (CPM) costs during this period, divided by the period length in days."
  },
  {
    field: "clicks",
    headerName: "Clicks",
    headerAlign: "left",
    width: 60,
    valueFormatter: intFormatter,
    type: "number",
    description: "The number of clicks."
  },
  {
    field: "ctr",
    headerName: "CTR",
    headerAlign: "left",
    width: 70,
    valueFormatter: percentageFormatter,
    type: "number",
    description: "The number of clicks your ad receives (Clicks) divided by the number of times your ad is shown (Impressions)."
  },
  {
    field: "averageCpc",
    headerName: "Average CPC",
    headerAlign: "left",
    width: 70,
    valueFormatter: currencyFormatter,
    type: "number",
    description: "The total cost of all clicks divided by the total number of clicks received."
  },
  {
    field: "budget",
    headerName: "Daily Budget",
    headerAlign: "left",
    width: 80,
    valueFormatter: currencyFormatter,
    type: "number",
    cellClassName: "budget-cell",
    description: "The amount of the budget, in the local currency for the account. Monthly spend is capped at 30.4 times this amount."
  },
  {
    field: "budgetPercentageSpent",
    headerName: "Budget Spent %",
    headerAlign: "left",
    width: 80,
    valueFormatter: percentageFormatter,
    type: "number",
    description: "The percentage of budget spent."
  },
  {
    field: "lostImpressions",
    headerName: "Lost Impressions",
    headerAlign: "left",
    width: 100,
    valueFormatter: intFormatter,
    type: "number",
    description: "The number of impressions lost due to budget constraints."
  },
  {
    field: "lostClicks",
    headerName: "Lost Clicks",
    headerAlign: "left",
    width: 75,
    valueFormatter: intFormatter,
    type: "number",
    description: "The number of clicks lost due to budget constraints."
  },
  {
    field: "availableCapacity",
    headerName: "Available Extra Capacity",
    headerAlign: "left",
    width: 85,
    valueFormatter: currencyFormatter,
    type: "number",
    description: "The extra budget that can be allocated to this campaign over the time frame."
  },
  {
    field: "availableDailyCapacity",
    headerName: "Available Daily Extra Capacity",
    headerAlign: "left",
    width: 100,
    valueFormatter: currencyFormatter,
    type: "number",
    description: "The extra daily budget that can be allocated to this campaign."
  },
];

// Columns for the proposed budgets preview
const budgetEditorPreviewColumns = [...budgetEditorColumns];
budgetEditorPreviewColumns.push({
  field: "proposedBudget",
  headerName: "Proposed Daily Budget",
  headerAlign: "left",
  width: 85,
  valueFormatter: currencyFormatter,
  editable: true,
  type: "number"
})

const budgetPacingColumns = [
  { field: "name", headerName: "Budget Group", width: 150, type: "string" },
  { field: "budgetStatus", headerName: "Status", width: 100, type: "singleSelect", valueOptions: enumOptionLabels.budgetGroupStatus },
  { field: "amount", headerName: "Amount",  width: 150, valueFormatter: currencyFormatter, type: "number" },
  { field: "generatedOn", headerName: "Generated On", width: 200, type: "dateTime" },
  { field: "startDate", headerName: "Start Date", width: 100, type: "date" },
  { field: "endDate", headerName: "End Date", width: 100, type: "date" },
  { field: "totalDays", headerName: "Total Days", width: 100, valueFormatter: intFormatter, type: "number" },
  { field: "timeframeEndDate", headerName: "Timeframe End Date", width: 175, type: "date" },
  { field: "cumulativeCost", headerName: "Cumulative Cost", width: 175, valueFormatter: currencyFormatter, type: "number" },
  { field: "forecastTotalSpend", headerName: "Forecast Total Spend", width: 175, valueFormatter: currencyFormatter, type: "number" },
  { field: "percentageForecastTotalSpend", headerName: "Forecast Total Spend (%)", width: 175, valueFormatter: percentageFormatter, type: "number" }
]

export {
  budgetEditorColumns,
  budgetPacingColumns,
  budgetEditorPreviewColumns,
  budgetEditorVisibilityModel
};
